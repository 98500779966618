import React, { useRef, useState } from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import Interweave from "interweave";
import { IButtonProps, Icon, MaybeElement } from "@blueprintjs/core";
import { Alignment, Position, Classes } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import type { IconName } from "@blueprintjs/icons";
import { useSelector } from "react-redux";
import { isSelectScreenTheme } from "selectors/appThemingSelectors";

import type { ComponentProps } from "widgets/BaseComponent";

import { useScript, ScriptStatus, AddScriptTo } from "utils/hooks/useScript";
import {
  GOOGLE_RECAPTCHA_KEY_ERROR,
  GOOGLE_RECAPTCHA_DOMAIN_ERROR,
  createMessage,
} from "ee/constants/messages";

import ReCAPTCHA from "react-google-recaptcha";
import { Colors } from "constants/Colors";
import _, { isEmpty } from "lodash";
import type {
  ButtonPlacement,
  ButtonVariant,
  RecaptchaType,
} from "components/constants";
import { ButtonVariantTypes, RecaptchaTypes } from "components/constants";
import {
  getCustomBackgroundColor,
  getCustomBorderColor,
  getCustomJustifyContent,
  getAlignText,
  getComplementaryGrayscaleColor,
  calculateHoverColor,
} from "widgets/WidgetUtils";
import { DragContainer } from "./DragContainer";
import type { ButtonContainerProps } from "./DragContainer";
import { buttonHoverActiveStyles } from "./utils";
import type { ThemeProp } from "WidgetProvider/constants";
import { toast } from "design-system";

import { Button as AntdButton, ConfigProvider, Popover } from "antd";
import { Button } from "@blueprintjs/core";
import AntdIcon from "components/common/AntdIcon";
import tinycolor from "tinycolor2";
import { addOverlayEffect } from "pages/utils";
import { renderVantIcon } from "pages/Editor/ViewerLayoutEditor/IconSelector";
import { getIconParams } from "utils/widgetIcon";

const RecaptchaWrapper = styled.div`
  position: relative;
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

const StyledButton = styled(
  ({
    width,
    height,
    borderRadius,
    boxShadow,
    boxShadowColor,
    buttonBackgroundImage,
    buttonColor,
    buttonVariant,
    textColor,
    ...props
  }) => (
    <AntdButton
      aria-label={"antdButton"}
      style={{
        width: `${width}`,
        height: `${height}`,
        borderRadius: `${buttonVariant !== ButtonVariantTypes.LINK ? borderRadius : "none"}`,
        boxShadow: `${buttonVariant !== ButtonVariantTypes.LINK ? boxShadow : "none"}`,
        ...(buttonBackgroundImage
          ? { background: `url(${buttonBackgroundImage})` }
          : {}),
        // background: buttonBackgroundImage
        //   ? `url(${buttonBackgroundImage})`
        //   : "",
      }}
      {...props}
    />
  ),
)`
  border: none;
  .ant-btn-text:not(:disabled):not(.ant-btn-disabled):active {
    color: ${(props) => props.buttonColor} !important;
    background: ${(props) => props.buttonColor} !important;
  }
  .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${(props) => props.buttonColor} !important;
    background: ${(props) => props.buttonColor} !important;
  }
`;

const ButtonContainer = styled.div<ButtonContainerProps>`
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  .ant-btn-icon {
    display: inline-block;
    width: 100%;
  }
  ${({ maxWidth, minHeight, minWidth, shouldFitContent }) =>
    shouldFitContent &&
    css`
      .ant-btn {
        display: flex;
        width: auto;
        ${minWidth ? `min-width: ${minWidth}px;` : ""}
        ${minHeight ? `min-height: ${minHeight}px;` : ""}
      ${maxWidth ? `max-width: ${maxWidth}px;` : ""}
      }
    `}
`;

export interface ButtonStyleProps {
  buttonColor?: string;
  buttonBackgroundImage?: string;
  buttonVariant?: ButtonVariant;
  boxShadow?: string;
  boxShadowColor?: string;
  borderRadius?: string;
  iconName?: any;
  iconAlign?: Alignment;
  shouldFitContent?: boolean;
  placement?: ButtonPlacement;
  isScreen?: boolean;
  maxWidth?: number;
  minWidth?: number;
  minHeight?: number;
  NotRenderAfter?: boolean;
  tooltip?: string;
  textColor?: string;
}

// To be used in any other part of the app
export function BaseButton(props: IButtonProps & ButtonStyleProps) {
  const isScreen = useSelector(isSelectScreenTheme);
  const {
    borderRadius,
    boxShadow,
    boxShadowColor,
    buttonBackgroundImage,
    buttonColor,
    buttonVariant,
    className,
    disabled,
    icon,
    iconAlign,
    iconName,
    loading,
    maxWidth,
    minHeight,
    minWidth,
    NotRenderAfter,
    onClick,
    placement,
    rightIcon,
    text,
    type,
    tooltip,
    shouldFitContent,
    textColor,
  } = props;
  const isRightAlign = iconAlign === Alignment.RIGHT;
  const iconObj = getIconParams(iconName);
  const AntdButtonStyleTypes: Record<string, string> = {
    PRIMARY: "primary",
    SECONDARY: "default",
    TERTIARY: "text",
    LINK: "link",
  };

  const getJustifyContent = () => {
    switch (placement) {
      case "START":
        return "start";
      case "BETWEEN":
        return "space-between";
      default:
        return "center";
    }
  };

  // 渲染文本和图标
  const renderContent = () => {
    const iconStyle = {
      fontSize: "20px",
    };
    const renderStyle = {
      display: "flex",
      justifyContent: getJustifyContent(),
      alignItems: "center",
      width: "100%",
    };
    if (!isRightAlign) {
      return (
        <div style={renderStyle}>
          {iconObj.type === "appsmith" ? (
            <Icon icon={iconObj.iconName || ""} />
          ) : (
            <AntdIcon type={iconObj.iconName ?? ""} style={iconStyle} />
          )}
          <pre style={{ marginLeft: iconObj.iconName ? "8px" : 0 }}>{text}</pre>
        </div>
      );
    } else {
      return (
        <div style={renderStyle}>
          <pre style={{ marginRight: iconObj.iconName ? "8px" : 0 }}>
            {text}
          </pre>
          {iconObj.type === "appsmith" ? (
            <Icon icon={iconObj.iconName as IconName} />
          ) : (
            <AntdIcon type={iconObj.iconName ?? ""} style={iconStyle} />
          )}
        </div>
      );
    }
  };

  const tooltipContent = () => {
    return !isEmpty(tooltip) ? (
      <div className="text-[#000]">{tooltip}</div>
    ) : (
      ""
    );
  };

  return (
    <ButtonContainer
      maxWidth={maxWidth}
      minHeight={minHeight}
      minWidth={minWidth}
      shouldFitContent={shouldFitContent}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: buttonColor,
            colorLink: buttonColor,
            colorBgTextActive: addOverlayEffect(
              calculateHoverColor(buttonColor),
              0.5,
            ),
            colorTextLightSolid:
              buttonVariant === ButtonVariantTypes.PRIMARY
                ? getComplementaryGrayscaleColor(buttonColor)
                : addOverlayEffect(calculateHoverColor(buttonColor), 0.5),
            colorText:
              buttonVariant === ButtonVariantTypes.TERTIARY
                ? addOverlayEffect(calculateHoverColor(buttonColor), 0.2)
                : buttonVariant === ButtonVariantTypes.LINK
                  ? textColor
                  : "initial",
          },
          components: {
            Button: {
              textHoverBg: addOverlayEffect(
                calculateHoverColor(buttonColor),
                0.8,
              ),
              contentFontSize: 15,
              defaultColor: textColor,
              textTextColor: textColor,
              primaryColor: textColor || "#fff",
              textTextHoverColor: buttonColor,
              colorLink: textColor,
            },
          },
        }}
      >
        <Popover content={tooltipContent()}>
          <StyledButton
            width="100%"
            height="100%"
            type={
              AntdButtonStyleTypes[
                buttonVariant === undefined ? "PRIMARY" : buttonVariant
              ]
            }
            onClick={onClick}
            disabled={disabled}
            borderRadius={borderRadius}
            boxShadow={boxShadow}
            boxShadowColor={boxShadowColor}
            buttonBackgroundImage={buttonBackgroundImage}
            buttonColor={buttonColor}
            buttonVariant={buttonVariant}
            textColor={textColor}
          >
            {renderContent()}
          </StyledButton>
        </Popover>
      </ConfigProvider>
    </ButtonContainer>
  );
}

BaseButton.defaultProps = {
  buttonColor: Colors.GREEN,
  buttonVariant: ButtonVariantTypes.PRIMARY,
  disabled: false,
  text: "Button Text",
  minimal: true,
};

export enum ButtonType {
  SUBMIT = "submit",
  RESET = "reset",
  BUTTON = "button",
}

interface RecaptchaProps {
  googleRecaptchaKey?: string;
  clickWithRecaptcha: (token: string) => void;
  handleRecaptchaV2Loading?: (isLoading: boolean) => void;
  recaptchaType?: RecaptchaType;
}

interface ButtonComponentProps extends ComponentProps {
  text?: string;
  icon?: IconName | MaybeElement;
  tooltip?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isDisabled?: boolean;
  isLoading: boolean;
  shouldFitContent: boolean;
  rightIcon?: IconName | MaybeElement;
  type: ButtonType;
  buttonColor?: string;
  buttonBackgroundImage?: string;
  buttonVariant?: ButtonVariant;
  borderRadius?: string;
  boxShadow?: string;
  boxShadowColor?: string;
  iconName?: any;
  iconAlign?: Alignment;
  placement?: ButtonPlacement;
  className?: string;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  textColor?: string;
}

interface RecaptchaV2ComponentPropType {
  children: any;
  className?: string;
  isDisabled?: boolean;
  recaptchaType?: RecaptchaType;
  isLoading: boolean;
  handleError: (event: React.MouseEvent<HTMLElement>, error: string) => void;
}

function RecaptchaV2Component(
  props: RecaptchaV2ComponentPropType & RecaptchaProps,
) {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [isInvalidKey, setInvalidKey] = useState(false);
  const handleRecaptchaLoading = (isloading: boolean) => {
    props.handleRecaptchaV2Loading && props.handleRecaptchaV2Loading(isloading);
  };
  const handleBtnClick = async (event: React.MouseEvent<HTMLElement>) => {
    if (props.isDisabled) return;
    if (props.isLoading) return;
    if (isInvalidKey) {
      // Handle incorrent google recaptcha site key
      props.handleError(event, createMessage(GOOGLE_RECAPTCHA_KEY_ERROR));
    } else {
      handleRecaptchaLoading(true);
      try {
        await recaptchaRef?.current?.reset();
        const token = await recaptchaRef?.current?.executeAsync();
        if (token) {
          props.clickWithRecaptcha(token);
        } else {
          // Handle incorrent google recaptcha site key
          props.handleError(event, createMessage(GOOGLE_RECAPTCHA_KEY_ERROR));
        }
        handleRecaptchaLoading(false);
      } catch (err) {
        handleRecaptchaLoading(false);
        // Handle error due to google recaptcha key of different domain
        props.handleError(event, createMessage(GOOGLE_RECAPTCHA_DOMAIN_ERROR));
      }
    }
  };
  return (
    <RecaptchaWrapper className={props.className} onClick={handleBtnClick}>
      {props.children}
      <ReCAPTCHA
        onErrored={() => setInvalidKey(true)}
        ref={recaptchaRef}
        sitekey={props.googleRecaptchaKey || ""}
        size="invisible"
      />
    </RecaptchaWrapper>
  );
}

interface RecaptchaV3ComponentPropType {
  children: any;
  className?: string;
  isDisabled?: boolean;
  recaptchaType?: RecaptchaType;
  isLoading: boolean;
  handleError: (event: React.MouseEvent<HTMLElement>, error: string) => void;
}

function RecaptchaV3Component(
  props: RecaptchaV3ComponentPropType & RecaptchaProps,
) {
  // Check if a string is a valid JSON string
  const checkValidJson = (inputString: string): boolean => {
    return !inputString.includes("");
  };

  const handleBtnClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.isDisabled) return;
    if (props.isLoading) return;
    if (status === ScriptStatus.READY) {
      (window as any).grecaptcha.ready(() => {
        try {
          (window as any).grecaptcha
            .execute(props.googleRecaptchaKey, {
              action: "submit",
            })
            .then((token: any) => {
              props.clickWithRecaptcha(token);
            })
            .catch(() => {
              // Handle incorrent google recaptcha site key
              props.handleError(
                event,
                createMessage(GOOGLE_RECAPTCHA_KEY_ERROR),
              );
            });
        } catch (err) {
          // Handle error due to google recaptcha key of different domain
          props.handleError(
            event,
            createMessage(GOOGLE_RECAPTCHA_DOMAIN_ERROR),
          );
        }
      });
    }
  };

  let validGoogleRecaptchaKey = props.googleRecaptchaKey;
  if (validGoogleRecaptchaKey && !checkValidJson(validGoogleRecaptchaKey)) {
    validGoogleRecaptchaKey = undefined;
  }
  const status = useScript(
    `https://www.google.com/recaptcha/api.js?render=${validGoogleRecaptchaKey}`,
    AddScriptTo.HEAD,
  );
  return (
    <div className={props.className} onClick={handleBtnClick}>
      {props.children}
    </div>
  );
}

const Wrapper = styled.div`
  height: 100%;
`;

function BtnWrapper(
  props: {
    children: any;
    className?: string;
    isDisabled?: boolean;
    isLoading: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  } & RecaptchaProps,
) {
  const hasOnClick = Boolean(
    props.onClick && !props.isLoading && !props.isDisabled,
  );
  if (!props.googleRecaptchaKey) {
    return (
      <Wrapper
        className={props.className}
        onClick={hasOnClick ? props.onClick : undefined}
      >
        {props.children}
      </Wrapper>
    );
  } else {
    const handleError = (
      event: React.MouseEvent<HTMLElement>,
      error: string,
    ) => {
      toast.show(error, {
        kind: "error",
      });
      props.onClick && !props.isLoading && props.onClick(event);
    };
    if (props.recaptchaType === RecaptchaTypes.V2) {
      return <RecaptchaV2Component {...props} handleError={handleError} />;
    } else {
      return <RecaptchaV3Component {...props} handleError={handleError} />;
    }
  }
}

// To be used with the canvas
function ButtonComponent(props: ButtonComponentProps & RecaptchaProps) {
  const btnWrapper = (
    <BtnWrapper
      className={props.className}
      clickWithRecaptcha={props.clickWithRecaptcha}
      googleRecaptchaKey={props.googleRecaptchaKey}
      handleRecaptchaV2Loading={props.handleRecaptchaV2Loading}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      onClick={props.onClick}
      recaptchaType={props.recaptchaType}
    >
      <BaseButton
        borderRadius={props.borderRadius}
        boxShadow={props.boxShadow}
        boxShadowColor={props.boxShadowColor}
        buttonBackgroundImage={props.buttonBackgroundImage}
        buttonColor={props.buttonColor}
        buttonVariant={props.buttonVariant}
        disabled={props.isDisabled}
        icon={props.icon}
        iconAlign={props.iconAlign}
        iconName={props.iconName}
        loading={props.isLoading}
        maxWidth={props.maxWidth}
        minHeight={props.minHeight}
        minWidth={props.minWidth}
        placement={props.placement}
        rightIcon={props.rightIcon}
        shouldFitContent={props.shouldFitContent}
        text={props.text}
        type={props.type}
        tooltip={props.tooltip}
        textColor={props.textColor}
      />
    </BtnWrapper>
  );
  return btnWrapper;
}

export default ButtonComponent;
