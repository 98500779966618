import { Alignment, Icon, type IconName } from "@blueprintjs/core";
import { compact, isArray, isEmpty, orderBy } from "lodash";
import React, { Suspense, useCallback } from "react";

import { LabelPosition } from "components/constants";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import type { TextSize } from "constants/WidgetConstants";
import type { ValidationResponse } from "constants/WidgetValidation";
import { ValidationTypes } from "constants/WidgetValidation";
import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import { EvaluationSubstitutionType } from "entities/DataTree/dataTreeFactory";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";
import {
  isAutoHeightEnabledForWidget,
  DefaultAutocompleteDefinitions,
  isCompactMode,
} from "widgets/WidgetUtils";
import type { WidgetProps, WidgetState } from "../../BaseWidget";
import BaseWidget from "../../BaseWidget";
import { Direction, SegmenteSource, type SegmenteOption } from "../constants";
import { isAutoLayout } from "layoutSystems/autolayout/utils/flexWidgetUtils";
import type {
  AnvilConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import IconSVG from "../icon.svg";
import type {
  SnipingModeProperty,
  PropertyUpdates,
} from "WidgetProvider/constants";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import { FlexVerticalAlignment } from "layoutSystems/common/utils/constants";
import { Skeleton } from "antd";
import { retryPromise } from "utils/AppsmithUtils";
import segmetdItemConfig from "./propertyConfig.ts/segmetdItemConfig";
import { updateSegmetdItemsSource } from "./propertyConfig.ts/propertyUtils";
import { getIconParams } from "utils/widgetIcon";
import AntdIcon from "components/common/AntdIcon";

const LazySegmentedComponent = React.lazy(async () =>
  retryPromise(async () => import("../component")),
);

/**
 * 验证规则：
 * 1. 此属性将采用以下格式的值：Array<{ "label": "string", "value": "string" | number}>
 * 2. `value` 属性应仅包含唯一值。
 * 3. 所有值属性的数据类型应相同。
 */
export function optionsCustomValidation(
  options: unknown,
  props: any,
  _: any,
): ValidationResponse {
  const validationUtil = (
    options: { label: string; value: string | number }[],
    _: any,
  ) => {
    let _isValid = true;
    let message = { name: "", message: "" };
    let valueType = "";
    const uniqueLabels: Record<string | number, string> = {};

    for (let i = 0; i < options.length; i++) {
      const { label, value } = options[i];
      if (!valueType) {
        valueType = typeof value;
      }
      //Checks the uniqueness all the values in the options
      if (!uniqueLabels.hasOwnProperty(value)) {
        uniqueLabels[value] = "";
      } else {
        _isValid = false;
        message = {
          name: "ValidationError",
          message: "path:value must be unique. Duplicate values found",
        };
        break;
      }

      //Check if the required field "label" is present:
      if (!label) {
        _isValid = false;
        message = {
          name: "ValidationError",
          message:
            "Invalid entry at index: " + i + ". Missing required key: label",
        };
        break;
      }

      //Validation checks for the the label.
      if (
        _.isNil(label) ||
        label === "" ||
        (typeof label !== "string" && typeof label !== "number")
      ) {
        _isValid = false;
        message = {
          name: "ValidationError",
          message:
            "Invalid entry at index: " +
            i +
            ". Value of key: label is invalid: This value does not evaluate to type string",
        };
        break;
      }

      //Check if all the data types for the value prop is the same.
      if (typeof value !== valueType) {
        _isValid = false;
        message = {
          name: "TypeError",
          message: "All value properties in options must have the same type",
        };
        break;
      }

      //Check if the each object has value property.
      if (_.isNil(value)) {
        _isValid = false;
        message = {
          name: "TypeError",
          message:
            'This value does not evaluate to type Array<{ "label": "string", "value": "string" | number }>',
        };
        break;
      }
    }

    return {
      isValid: _isValid,
      parsed: _isValid ? options : [],
      messages: [message],
    };
  };

  const invalidResponse = {
    isValid: false,
    parsed: [],
    messages: [
      {
        name: "TypeError",
        message:
          'This value does not evaluate to type Array<{ "label": "string", "value": "string" | number }>',
      },
    ],
  };
  try {
    if (_.isString(options)) {
      options = JSON.parse(options as string);
    }

    if (Array.isArray(options)) {
      return validationUtil(options, _);
    } else {
      return invalidResponse;
    }
  } catch (e) {
    return invalidResponse;
  }
}
function defaultOptionValidation(
  value: unknown,
  props: any,
  _: any,
): ValidationResponse {
  //Checks if the value is not of object type in {{}}
  if (_.isObject(value)) {
    return {
      isValid: false,
      parsed: JSON.stringify(value, null, 2),
      messages: [
        {
          name: "TypeError",
          message: "This value does not evaluate to type: string or number",
        },
      ],
    };
  }

  //Checks if the value is not of boolean type in {{}}
  if (_.isBoolean(value)) {
    return {
      isValid: false,
      parsed: value,
      messages: [
        {
          name: "TypeError",
          message: "This value does not evaluate to type: string or number",
        },
      ],
    };
  }

  return {
    isValid: true,
    parsed: value,
  };
}

class AntdSegmentedWidget extends BaseWidget<
  AntdSegmentedWidgetProps,
  WidgetState
> {
  static type = "ANTD_SEGMENTED_WIDGET";

  static getConfig() {
    return {
      name: "分段控制器",
      iconSVG: IconSVG,
      tags: [WIDGET_TAGS.ANTD],
      needsMeta: true,
      searchTags: ["分段", "控制器", "segmented", "Segmented"],
      isModule: false,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 3,
        active: true,
      },
    };
  }

  static getDefaults() {
    return {
      rows: 6,
      columns: 20,
      animateLoading: false,
      label: "标签",
      labelPosition: LabelPosition.Top,
      labelAlignment: Alignment.LEFT,
      labelTextSize: "0.875rem",
      labelWidth: 5,
      options: [
        { label: "是", value: "Y" },
        { label: "否", value: "N" },
      ],
      defaultOptionValue: "Y",
      isRequired: false,
      isDisabled: false,
      isInline: true,
      widgetName: "Segmented",
      version: 1,
      flexVerticalAlignment: FlexVerticalAlignment.Top,
      widgetDirection: "horizontal",
      segmetdItemsSource: SegmenteSource.STATIC,
      segmetdItems: {
        segmetdItem1: {
          label: "是",
          value: "Y",
          id: "segmetdItem1",
          widgetId: "",
          disabled: false,
          index: 0,
          visiableItem: true,
          disabledItem: false,
        },
        segmetdItem2: {
          label: "否",
          value: "N",
          id: "segmetdItem2",
          widgetId: "",
          disabled: false,
          index: 1,
          visiableItem: true,
          disabledItem: false,
        },
      },
    };
  }

  static getMethods() {
    return {
      getSnipingModeUpdates: (
        propValueMap: SnipingModeProperty,
      ): PropertyUpdates[] => {
        return [
          {
            propertyPath: "options",
            propertyValue: propValueMap.data,
            isDynamicPropertyPath: true,
          },
        ];
      },
    };
  }

  static getAutoLayoutConfig() {
    return {
      defaults: {
        columns: 14,
        rows: 7,
      },
      disabledPropsDefaults: {
        labelPosition: LabelPosition.Top,
      },
      autoDimension: {
        height: true,
      },
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "240px",
              minHeight: "70px",
            };
          },
        },
      ],
      disableResizeHandles: {
        vertical: true,
      },
    };
  }

  static getAnvilConfig(): AnvilConfig | null {
    return {
      isLargeWidget: false,
      widgetSize: {
        maxHeight: {},
        maxWidth: {},
        minHeight: { base: "70px" },
        minWidth: { base: "240px" },
      },
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc":
        "Radio widget lets the user choose only one option from a predefined set of options. It is quite similar to a SingleSelect Dropdown in its functionality",
      "!url": "https://docs.appsmith.com/widget-reference/radio",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      options: "[$__dropdownOption__$]",
      selectedOptionValue: "string",
      isRequired: "bool",
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "数据",
        children: [
          {
            propertyName: "segmetdItemsSource",
            helpText: "设置分段控制器数据源",
            label: "模式",
            controlType: "ICON_TABS",
            defaultValue: SegmenteSource.STATIC,
            fullWidth: true,
            options: [
              {
                label: "静态",
                value: SegmenteSource.STATIC,
              },
              {
                label: "动态",
                value: SegmenteSource.DYNAMIC,
              },
            ],
            isJSConvertible: false,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
            updateHook: updateSegmetdItemsSource,
            dependencies: ["options"],
          },
          {
            helpText: "静态数据源",
            propertyName: "segmetdItems",
            controlType: "SEGMENTED_ITEMS",
            label: "选项列表",
            isBindProperty: false,
            isTriggerProperty: false,
            hidden: (props: AntdSegmentedWidgetProps) =>
              props.segmetdItemsSource === SegmenteSource.DYNAMIC,
            dependencies: ["segmetdItemsSource"],
            panelConfig: segmetdItemConfig,
            buttonText: "新建选项",
            placeholder: "请输入选项",
          },
          {
            helpText: "选项值唯一的数组",
            propertyName: "options",
            label: "选项",
            controlType: "OPTION_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.FUNCTION,
              params: {
                fn: optionsCustomValidation,
                expected: {
                  type: 'Array<{ "label": "string", "value": "string" | number}>',
                  example: `[{"label": "One", "value": "one"}]`,
                  autocompleteDataType: AutocompleteDataType.STRING,
                },
              },
            },
            hidden: (props: AntdSegmentedWidgetProps) =>
              props.segmetdItemsSource === SegmenteSource.STATIC,
            evaluationSubstitutionType:
              EvaluationSubstitutionType.SMART_SUBSTITUTE,
          },
          {
            helpText: "设置默认选中的选项",
            propertyName: "defaultOptionValue",
            label: "默认选中值",
            placeholderText: "Y",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            // hidden: (props: AntdSegmentedWidgetProps) =>
            //   props.segmetdItemsSource === SegmenteSource.STATIC,
            /**
             * Changing the validation to FUNCTION.
             * If the user enters Integer inside {{}} e.g. {{1}} then value should evalute to integer.
             * If user enters 1 e.g. then it should evaluate as string.
             */
            validation: {
              type: ValidationTypes.FUNCTION,
              params: {
                fn: defaultOptionValidation,
                expected: {
                  type: `string |\nnumber (only works in mustache syntax)`,
                  example: `abc | {{1}}`,
                  autocompleteDataType: AutocompleteDataType.STRING,
                },
              },
            },
          },
        ],
      },
      {
        sectionName: "标签",
        children: [
          {
            helpText: "设置组件标签文本",
            propertyName: "label",
            label: "文本",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入文本内容",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "设置组件标签位置",
            propertyName: "labelPosition",
            label: "位置",
            controlType: "ICON_TABS",
            fullWidth: true,
            hidden: isAutoLayout,
            options: [
              { label: "自动", value: LabelPosition.Auto },
              { label: "左", value: LabelPosition.Left },
              { label: "上", value: LabelPosition.Top },
            ],
            defaultValue: LabelPosition.Top,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "设置组件标签的对齐方式",
            propertyName: "labelAlignment",
            label: "对齐",
            controlType: "LABEL_ALIGNMENT_OPTIONS",
            fullWidth: false,
            options: [
              {
                startIcon: "align-left",
                value: Alignment.LEFT,
              },
              {
                startIcon: "align-right",
                value: Alignment.RIGHT,
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
            hidden: (props: AntdSegmentedWidgetProps) =>
              props.labelPosition !== LabelPosition.Left,
            dependencies: ["labelPosition"],
          },
          {
            helpText: "设置组件标签占用的列数",
            propertyName: "labelWidth",
            label: "宽度（所占列数）",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
            hidden: (props: AntdSegmentedWidgetProps) =>
              props.labelPosition !== LabelPosition.Left,
            dependencies: ["labelPosition"],
          },
        ],
      },
      {
        sectionName: "校验",
        children: [
          {
            propertyName: "isRequired",
            label: "必填",
            helpText: "强制用户填写",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "属性",
        children: [
          {
            helpText: "显示提示信息",
            propertyName: "labelTooltip",
            label: "提示",
            controlType: "INPUT_TEXT",
            placeholderText: "请至少输入 6 个字符",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "控制组件的冒号",
            propertyName: "showColon",
            label: "显示冒号",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            helpText: "控制组件的显示/隐藏",
            propertyName: "isVisible",
            label: "是否显示",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "isDisabled",
            label: "禁用",
            helpText: "让组件不可交互",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "animateLoading",
            label: "加载时显示动画",
            controlType: "SWITCH",
            helpText: "组件依赖的数据加载时显示加载动画",
            defaultValue: false,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "事件",
        children: [
          {
            helpText: "选中项改变时触发",
            propertyName: "onChange",
            label: "onChange",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "方向",
        children: [
          {
            propertyName: "widgetDirection",
            helpText: "设置组件方向",
            label: "方向",
            controlType: "ICON_TABS",
            defaultValue: "horizontal",
            fullWidth: true,
            isBindProperty: true,
            isTriggerProperty: false,
            options: [
              {
                label: "水平",
                value: "horizontal",
              },
              {
                label: "垂直",
                value: "vertical",
              },
            ],
          },
        ],
      },
      {
        sectionName: "属性",
        children: [
          {
            propertyName: "shape",
            label: "形状",
            helpText: "组件形状",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "默认",
                value: "default",
              },
              {
                label: "胶囊型",
                value: "round",
              },
            ],
            defaultValue: "default",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
        ],
      },
      {
        sectionName: "背景样式",
        children: [
          {
            propertyName: "itemSelectedBg",
            label: "指示器背景",
            helpText: "设置指示器背景颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "itemBg",
            label: "选项背景",
            helpText: "设置选项背景颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "标签样式",
        children: [
          {
            propertyName: "labelTextColor",
            label: "标签颜色",
            helpText: "设置标签字体颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "labelTextSize",
            label: "标签大小",
            helpText: "设置标签字体大小",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "labelStyle",
            label: "标签字体强调",
            helpText: "设置标签字体是否加粗或斜体",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "颜色配置",
        children: [
          {
            propertyName: "selectedTextColor",
            helpText: "设置选中文本颜色",
            label: "选中文本颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: true,
            customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
            evaluatedDependencies: ["sourceData"],
            validation: {
              type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
              params: {
                type: ValidationTypes.TEXT,
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "textColor",
            helpText: "设置选项指示器文本颜色",
            label: "文本颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: true,
            isTriggerProperty: false,
            isJSConvertible: true,
            customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
            evaluatedDependencies: ["sourceData"],
            validation: {
              type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
              params: {
                type: ValidationTypes.TEXT,
                regex: /^(?![<|{{]).+/,
              },
            },
          },
        ],
      },
      {
        sectionName: "轮廓样式",
        children: [
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText: "边框圆角样式",
            controlType: "BORDER_RADIUS_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
            hidden: (props: AntdSegmentedWidgetProps) =>
              props.shape === "round",
            dependencies: ["shape"],
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText: "组件轮廓投影",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "boxHeight",
            label: "默认行高",
            helpText: "组件高度",
            controlType: "ICON_TABS",
            fullWidth: true,
            hidden: isAutoLayout,
            options: [
              { label: "small", value: "small" },
              { label: "Default", value: "middle" },
              { label: "Large", value: "large" },
            ],
            defaultValue: "middle",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap() {
    return {
      selectedOption:
        "{{_.find(this.options, { value: this.selectedOptionValue })}}",
      isValid: `{{ this.isRequired ? !!this.selectedOptionValue : true }}`,
      value: `{{this.selectedOptionValue}}`,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {
      selectedOptionValue: "defaultOptionValue",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedOptionValue: undefined,
      isDirty: false,
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      accentColor: "{{appsmith.theme.colors.primaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }

  componentDidUpdate(prevProps: AntdSegmentedWidgetProps): void {
    if (
      this.props.defaultOptionValue !== prevProps.defaultOptionValue &&
      this.props.isDirty
    ) {
      this.props.updateWidgetMetaProperty("isDirty", false);
    }
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
        setDisabled: {
          path: "isDisabled",
          type: "boolean",
        },
        setData: {
          path: "options",
          type: "array",
        },
      },
    };
  }

  // getVisibleItems = () => {
  //   const {
  //     segmetdItems,
  //     segmetdItemsSource,
  //     options,
  //     isDisabled,
  //     widgetDirection,
  //   } = this.props;

  //   const renderIcon = (item) => {
  //     const iconObj = getIconParams(item.iconName);
  //     const iconStyle = {
  //       color: isDisabled ? "rgba(0, 0, 0, 0.25)" : item.iconColor,
  //     };

  //     return iconObj.type === "appsmith" ? (
  //       <Icon icon={iconObj.iconName as IconName} style={iconStyle} />
  //     ) : (
  //       <AntdIcon
  //         type={iconObj.iconName ?? ""}
  //         style={{ ...iconStyle, fontSize: "18px" }}
  //       />
  //     );
  //   };

  //   const renderLabel = (item) => {
  //     const iconElement = renderIcon(item);
  //     const labelContent = (
  //       <div className="flex items-center">
  //         {widgetDirection === Direction.Horizontal &&
  //         item.iconAlign === Alignment.RIGHT ? (
  //           <>
  //             <span style={{ marginRight: item.iconName ? "6px" : "0px" }}>
  //               {item.label}
  //             </span>
  //             {item.iconName && iconElement}
  //           </>
  //         ) : (
  //           <>
  //             {item.iconName && iconElement}
  //             <span style={{ marginLeft: item.iconName ? "6px" : "0px" }}>
  //               {item.label}
  //             </span>
  //           </>
  //         )}
  //       </div>
  //     );

  //     return widgetDirection === Direction.Vertical ? (
  //       <div>
  //         {item.iconAlign === Alignment.RIGHT ? (
  //           <>
  //             <div style={{ marginBottom: item.iconName ? "6px" : "0px" }}>
  //               {item.label}
  //             </div>
  //             {item.iconName && iconElement}
  //           </>
  //         ) : (
  //           <>
  //             {item.iconName && iconElement}
  //             <div style={{ marginTop: item.iconName ? "6px" : "0px" }}>
  //               {item.label}
  //             </div>
  //           </>
  //         )}
  //       </div>
  //     ) : (
  //       labelContent
  //     );
  //   };

  //   if (segmetdItemsSource === SegmenteSource.STATIC) {
  //     const visibleItems = Object.keys(segmetdItems).map((itemKey) => ({
  //       ...segmetdItems[itemKey],
  //       disabled: isDisabled || segmetdItems[itemKey].disabledItem,
  //       label: renderLabel(segmetdItems[itemKey]),
  //     })).filter((item) => item.visiableItem);
  //     return orderBy(visibleItems, ["index"], ["asc"]);
  //   } else if (
  //     segmetdItemsSource === SegmenteSource.DYNAMIC &&
  //     isArray(options) &&
  //     options.length
  //   ) {
  //     return orderBy(
  //       options.map((item, index) => ({
  //         ...item,
  //         id: index.toString(),
  //         index,
  //         widgetId: "",
  //         disabled: isDisabled,
  //       })),
  //       ["index"],
  //       ["asc"],
  //     );
  //   }
  //   return [];
  // };

  getVisibleItems = () => {
    const {
      segmetdItems,
      segmetdItemsSource,
      options,
      isDisabled,
      widgetDirection,
    } = this.props;

    // 使用一个集合来跟踪唯一的值
    const uniqueValues = new Set();

    const renderIcon = (item) => {
      const iconObj = getIconParams(item.iconName);
      const iconStyle = {
        color: isDisabled ? "rgba(0, 0, 0, 0.25)" : item.iconColor,
      };

      return iconObj.type === "appsmith" ? (
        <Icon icon={iconObj.iconName as IconName} style={iconStyle} />
      ) : (
        <AntdIcon
          type={iconObj.iconName ?? ""}
          style={{ ...iconStyle, fontSize: "18px" }}
        />
      );
    };

    const renderLabel = (item) => {
      const iconElement = renderIcon(item);
      const labelContent = (
        <div className="flex items-center">
          {widgetDirection === Direction.Horizontal &&
          item.iconAlign === Alignment.RIGHT ? (
            <>
              <span style={{ marginRight: item.iconName ? "6px" : "0px" }}>
                {item.label}
              </span>
              {item.iconName && iconElement}
            </>
          ) : (
            <>
              {item.iconName && iconElement}
              <span style={{ marginLeft: item.iconName ? "6px" : "0px" }}>
                {item.label}
              </span>
            </>
          )}
        </div>
      );

      return widgetDirection === Direction.Vertical ? (
        <div>
          {item.iconAlign === Alignment.RIGHT ? (
            <>
              <div style={{ marginBottom: item.iconName ? "6px" : "0px" }}>
                {item.label}
              </div>
              {item.iconName && iconElement}
            </>
          ) : (
            <>
              {item.iconName && iconElement}
              <div style={{ marginTop: item.iconName ? "6px" : "0px" }}>
                {item.label}
              </div>
            </>
          )}
        </div>
      ) : (
        labelContent
      );
    };

    if (segmetdItemsSource === SegmenteSource.STATIC) {
      const visibleItems = Object.keys(segmetdItems)
        .map((itemKey) => ({
          ...segmetdItems[itemKey],
          disabled: isDisabled || segmetdItems[itemKey].disabledItem,
          label: renderLabel(segmetdItems[itemKey]),
        }))
        .filter((item) => item.visiableItem && !uniqueValues.has(item.value) && uniqueValues.add(item.value));
      return orderBy(visibleItems, ["index"], ["asc"]);
    } else if (
      segmetdItemsSource === SegmenteSource.DYNAMIC &&
      isArray(options) &&
      options.length
    ) {
      return orderBy(
        options.filter(item => !uniqueValues.has(item.value) && uniqueValues.add(item.value)).map((item, index) => ({
          ...item,
          id: index.toString(),
          index,
          widgetId: "",
          disabled: isDisabled,
        })),
        ["index"],
        ["asc"],
      );
    }
    return [];
  };

  getWidgetView() {
    const {
      isDisabled,
      isLoading,
      label,
      labelAlignment,
      labelPosition,
      labelStyle,
      labelTextColor,
      labelTextSize,
      options,
      selectedOptionValue,
      widgetId,
      accentColor,
      widgetDirection,
      shape,
      itemSelectedBg,
      itemBg,
      borderRadius,
      boxShadow,
      boxHeight,
      iconName,
      defaultOptionValue,
      selectedTextColor,
      textColor,
      segmetdItems,
      segmetdItemsSource,
    } = this.props;

    const { componentHeight } = this.props;

    return (
      <Suspense fallback={<Skeleton />}>
        <LazySegmentedComponent
          accentColor={accentColor}
          compactMode={isCompactMode(componentHeight)}
          disabled={isDisabled}
          height={componentHeight}
          isDynamicHeightEnabled={isAutoHeightEnabledForWidget(this.props)}
          key={widgetId}
          labelAlignment={labelAlignment}
          labelPosition={labelPosition}
          labelStyle={labelStyle}
          labelText={label}
          labelTextColor={labelTextColor}
          labelTextSize={labelTextSize}
          labelTooltip={this.props.labelTooltip}
          labelWidth={this.props.labelComponentWidth}
          loading={isLoading}
          onSegmenteChange={this.onSegmenteChange}
          options={isArray(options) ? compact(options) : []}
          required={this.props.isRequired}
          selectedOptionValue={selectedOptionValue}
          showColon={this.props.showColon}
          widgetId={widgetId}
          widgetDirection={widgetDirection}
          shape={shape}
          itemSelectedBg={itemSelectedBg}
          itemBg={itemBg}
          borderRadius={borderRadius}
          boxShadow={boxShadow}
          boxHeight={boxHeight}
          iconName={iconName}
          getVisibleItems={this.getVisibleItems}
          defaultOptionValue={defaultOptionValue}
          selectedTextColor={selectedTextColor}
          textColor={textColor}
          segmetdItems={segmetdItems}
          segmetdItemsSource={segmetdItemsSource}
        />
      </Suspense>
    );
  }

  onSegmenteChange = (updatedValue: string) => {
    if (!this.props.isDirty) {
      this.props.updateWidgetMetaProperty("isDirty", true);
    }
    this.props.updateWidgetMetaProperty("selectedOptionValue", updatedValue, {
      triggerPropertyName: "onChange",
      dynamicString: this.props.onChange,
      event: {
        type: EventType.ON_OPTION_CHANGE,
      },
    });
  };
}

export interface AntdSegmentedWidgetProps extends WidgetProps {
  options: SegmenteOption[];
  selectedOptionValue: string;
  onSelectionChange: string;
  defaultOptionValue: string;
  isRequired?: boolean;
  isDisabled: boolean;
  label: string;
  labelPosition?: LabelPosition;
  labelAlignment?: Alignment;
  labelWidth?: number;
  labelTextColor?: string;
  labelTextSize?: TextSize;
  labelStyle?: string;
  isDirty: boolean;
  accentColor: string;
  labelComponentWidth?: number;
  showColon?: boolean;
  widgetDirection: Direction;
  shape: "default" | "round";
  itemSelectedBg: string;
  itemBg: string;
  borderRadius?: string;
  boxShadow?: string;
  boxHeight?: string;
  iconName?: IconName | string;
}

export default AntdSegmentedWidget;
