import React, { Suspense } from "react";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import type { JsonSchemaFormComponentProps } from "../component";
import JsonSchemaFormComponent from "../component";
import { contentConfig, styleConfig, styleSheetConfig } from "./propertyConfig";
import type {
  ButtonStyles,
  ChildStylesheet,
  SetterConfig,
  Stylesheet,
} from "entities/AppTheming";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import type { AutocompletionDefinitions } from "WidgetProvider/constants";
import { generateTypeDef } from "utils/autocomplete/defCreatorUtils";
import { isEqual, omitBy, isNil, isEmpty } from "lodash";
import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import { Colors } from "constants/Colors";
import { RegisteredWidgetFeatures } from "utils/WidgetFeatures";
import { Skeleton } from "antd";
import { generateReactKey } from "widgets/WidgetUtils";
import { retryPromise } from "utils/AppsmithUtils";

const defaultSchema = {
  title: "Json Schema表单",
  description: "定制性更强",
  type: "object",
  required: ["companyName", "establishedBy", "employees"],
  properties: {
    companyName: {
      type: "string",
      title: "公司名称",
    },
    employees: {
      type: "integer",
      title: "员工人数",
    },
    establishedBy: {
      type: "string",
      title: "成立日期",
    },
  },
};
const defaultSchemas = [
  {
    stepName: "第一部分",
    data: {
      title: "第一部分",
      description: "基本信息",
      type: "object",
      required: ["companyName", "employees"],
      properties: {
        companyName: {
          type: "string",
          title: "公司名称",
        },
        employees: {
          type: "integer",
          title: "员工人数",
        },
      },
    },
  },
  {
    stepName: "第二部分",
    data: {
      title: "第二部分",
      description: "二级信息",
      type: "object",
      required: ["companyContainer", "companyAddress"],
      properties: {
        companyContainer: {
          type: "string",
          title: "公司法人",
        },
        companyAddress: {
          type: "string",
          title: "公司地址",
        },
      },
    },
  },
];

const LazyJsonSchemaFormComponent = React.lazy(async () =>
  retryPromise(async () => import("../component")),
);
class JsonSchemaFormWidget extends BaseWidget<
  JsonSchemaFormWidgetProps,
  WidgetState
> {
  static type = "JSONSCHEMAFORM_WIDGET";

  state = {
    isSubmitting: false,
  };

  static getConfig() {
    return {
      name: "JsonSchema表单", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      tags: [WIDGET_TAGS.INPUTS],
      needsMeta: true, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
      isModule: false,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 1, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getDefaults() {
    return {
      widgetName: "JsonSchemaForm",
      backgroundColor: "#fff",
      borderColor: Colors.GREY_5,
      submitButtonLabel: "提交",
      showSubmit: true,
      borderWidth: "1",
      isStepForm: false,
      stepData: {
        [generateReactKey()]: defaultSchemas[0],
        [generateReactKey()]: defaultSchemas[1],
      },
      jsonSchema: defaultSchema,
      sourceData: {
        companyName: "",
        establishedBy: "",
        employees: 0,
        companyContainer: "李先生",
        companyAddress: "中国",
      },
      uiSchema: {
        companyName: {
          "ui:widget": "text",
          "ui:placeholder": "请输入公司名称",
          "ui:description": "请输入公司的全名",
        },
        establishedBy: {
          "ui:widget": "date",
          "ui:description": "请输入公司成立时间",
        },
        employees: {
          "ui:widget": "updown",
          "ui:description": "请输入员工总数，必须是整数",
        },
      },
      rows: 41,
      columns: 25,
      version: 1,
      cols: 1,
    };
  }

  static getAutoLayoutConfig() {
    return {};
  }

  componentDidMount(): void {
    const { jsonSchema, sourceData, uiSchema } = this.props;

    this.updateFormData(sourceData, jsonSchema, uiSchema);
  }

  componentDidUpdate(prevProps: JsonSchemaFormWidgetProps) {
    const { jsonSchema, sourceData, uiSchema } = this.props;

    if (
      !isEqual(prevProps.sourceData, sourceData) ||
      !isEqual(prevProps.jsonSchema, jsonSchema) ||
      !isEqual(prevProps.uiSchema, uiSchema)
    ) {
      this.updateFormData(sourceData, jsonSchema, uiSchema);
    }
  }

  static getPropertyPaneContentConfig() {
    return contentConfig;
  }

  static getPropertyPaneStyleConfig() {
    return styleConfig;
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getStylesheetConfig(): Stylesheet<ChildStylesheet & ButtonStyles> {
    return styleSheetConfig;
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      formSchema: {},
      formData: {},
      formUI: {},
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return (widget: JsonSchemaFormComponentProps) => {
      const definitions: AutocompletionDefinitions = {
        "!doc":
          "JSON Form widget can be used to auto-generate forms by providing a JSON source data.",
        // TODO: Update the url
        "!url": "https://docs.appsmith.com/widget-reference",
        formSchema: generateTypeDef(widget.formSchema),
        sourceData: generateTypeDef(widget.sourceData),
        formData: generateTypeDef(widget.formData),
        formUI: generateTypeDef(widget.formUI),
      };

      return definitions;
    };
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
        setSourceData: {
          path: "sourceData",
          type: "object",
        },
        setJsonSchema: {
          path: "jsonSchema",
          type: "object",
        },
        setUISchema: {
          path: "uiSchema",
          type: "object",
        },
      },
    };
  }

  updateFormData = (formData?: any, formSchema?: any, formUI?: any) => {
    const newFormData = omitBy(
      formData,
      (value) => isNil(value) || value === "",
    );
    this.props.updateWidgetMetaProperty("formData", newFormData);
    this.props.updateWidgetMetaProperty("formSchema", formSchema);
    this.props.updateWidgetMetaProperty("formUI", formUI);
  };

  validateRequiredFields = (formData: any, formSchema: any): boolean => {
    const requiredFields = formSchema.required || [];
    for (let field of requiredFields) {
      if (isEmpty(formData[field])) {
        if (formData[field] === 0) return true;
        return false;
      }
    }
    return true;
  };

  onSubmit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    // event.preventDefault();

    const { formData, formSchema } = this.props;

    if (!this.validateRequiredFields(formData, formSchema)) {
      return;
    }

    if (this.props.onSubmit) {
      this.setState({
        isSubmitting: true,
      });

      super.executeAction({
        triggerPropertyName: "onSubmit",
        dynamicString: this.props.onSubmit,
        event: {
          type: EventType.ON_SUBMIT,
          callback: this.handleSubmitResult,
        },
      });
    }
  };

  handleSubmitResult = () => {
    this.setState({
      isSubmitting: false,
    });
  };

  onUpdateWidgetProperty = (propertyName: string, propertyValue: any) => {
    this.updateWidgetProperty(propertyName, propertyValue);
  };

  onUpdateWidgetMetaProperty = (propertyName: string, propertyValue: any) => {
    this.props.updateWidgetMetaProperty(propertyName, propertyValue);
  };

  getFormData = () => this.props.formData;

  getWidgetView() {
    const {
      backgroundColor,
      borderColor,
      borderRadius,
      borderWidth,
      boxShadow,
      boxShadowColor,
      jsonSchema,
      showSubmit,
      submitButtonLabel,
      submitButtonStyles,
      stepData,
      isStepForm,
      uiSchema,
      widgetId,
      cols,
    } = this.props;
    const colSpan = Math.ceil(24 / cols);

    return (
      <Suspense fallback={<Skeleton />}>
        <LazyJsonSchemaFormComponent
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          borderRadius={borderRadius}
          borderWidth={borderWidth}
          boxShadow={boxShadow}
          boxShadowColor={boxShadowColor}
          formData={this.getFormData()}
          formSchema={jsonSchema}
          formUI={uiSchema}
          isSubmitting={this.state.isSubmitting}
          isStepForm={isStepForm}
          onSubmit={this.onSubmit}
          showSubmit={showSubmit}
          submitButtonLabel={submitButtonLabel}
          submitButtonStyles={submitButtonStyles}
          stepData={stepData}
          updateFormData={this.updateFormData}
          updateWidgetMetaProperty={this.onUpdateWidgetMetaProperty}
          updateWidgetProperty={this.onUpdateWidgetProperty}
          widgetId={widgetId}
          colSpan={colSpan}
        />
      </Suspense>
    );
  }
}

export type JsonSchemaFormWidgetProps = WidgetProps;

export default JsonSchemaFormWidget;
