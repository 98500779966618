import type { RcFile } from "antd/es/upload/interface";
/**
 * 文件类型
 * 注意：读取文件为二进制 Binary（已废弃，不推荐使用，大多数现代浏览器已经停止支持该方法）
 */
export enum FileDataTypes {
  NONE = "none",
  BASE64 = "Base64",
  BINARY = "Binary",
  ARRAY_BUFFER = "ArrayBuffer",
  TEXT = "Text",
}

export enum DataTypesHelperText {
  Base64 = "适合小文件（如图像），对于较大的文件，Base64 编码可能带来明显的性能下降",
  Text = "适用于文本类型的文件，比如 .txt、.csv、.json 等，注意文本格式无法直接处理二进制数据，如图像、视频、音频等。这需要先将二进制数据转换为文本格式（如 Base64）",
  Binary = "适用于图像、视频、音频 及特定的二进制数据，如 .exe； 二进制格式是一种将文件内容直接以二进制形式进行编码的方式。这意味着文件的原始数据（如图像、音频、视频等）将保持原样，注意：大多数现代浏览器已停止支持 readAsBinaryString 方法",
  ArrayBuffer = "适用于所有二进制数据，尤其适用于较大文件的传输和处理",
  none = "直接传递 File 对象，不在前端进行数据转换，在不确定数据类型的情况下推荐这种方式，可以避免性能问题",
}

type FileReaderResult<T extends FileDataTypes> = T extends "none"
  ? RcFile
  : T extends "ArrayBuffer"
    ? ArrayBuffer
    : T extends "Base64" | "Text" | "Binary"
      ? string
      : never;

class FileReadError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "FileReadError";
  }
}

export async function readFile<T extends FileDataTypes>(
  file: RcFile,
  type: T,
): Promise<FileReaderResult<T>> {
  if (type === "none") {
    return file as FileReaderResult<T>;
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      try {
        if (reader.result === null) {
          throw new FileReadError("File reading resulted in null");
        }
        resolve(reader.result as FileReaderResult<T>);
      } catch (error) {
        reject(
          new FileReadError(`Error reading file: ${(error as Error).message}`),
        );
      }
    };

    reader.onerror = (error) => {
      reject(
        new FileReadError(
          `File reading error: ${(error as unknown as Error).message}`,
        ),
      );
    };

    try {
      switch (type) {
        case "Base64":
          reader.readAsDataURL(file);
          break;
        case "Text":
          reader.readAsText(file);
          break;
        case "Binary":
          console.warn(
            "Warning: readAsBinaryString is deprecated and not supported in modern browsers",
          );
          reader.readAsBinaryString(file);
          break;
        case "ArrayBuffer":
          reader.readAsArrayBuffer(file);
          break;
        default:
          throw new FileReadError(`Unsupported file type: ${type}`);
      }
    } catch (error) {
      reject(
        new FileReadError(
          `Failed to initiate file reading: ${(error as Error).message}`,
        ),
      );
    }
  });
}
