import React from "react";

import type { ControlProps } from "./BaseControl";
import BaseControl from "./BaseControl";
import styled from "styled-components";
import {
  StyledPropertyPaneButton,
  StyledRefreshIcon,
  // StyledLayerBottomIcon,
  // StyledLayerDownIcon,
  // StyledLayerTopIcon,
  // StyledLayerUpIcon,
} from "./StyledControls";
import { Category, Size } from "design-system-old";
import * as _ from "lodash";
import { Tooltip } from "@blueprintjs/core";

export interface OnButtonClickProps {
  props: ControlProps;
  updateProperty: (propertyName: string, propertyValue: any) => void;
  deleteProperties: (propertyPaths: string[]) => void;
  batchUpdateProperties: (updates: Record<string, unknown>) => void;
}
export enum DynamicLayerZIndex {
  LAYER_TOP = "LAYER_TOP",
  LAYER_BOTTOM = "LAYER_BOTTOM",
  LAYER_UP = "LAYER_UP",
  LAYER_DOWN = "LAYER_DOWN",
}
export type ButtonControlProps = ControlProps & {
  onClick: (props: OnButtonClickProps) => void;
  buttonLabel: string;
  isDisabled?: (widgetProperties: any) => boolean;
};

interface ButtonControlState {
  isLoading: boolean;
}

const StyledButton = styled(StyledPropertyPaneButton)`
  width: 100%;
  display: flex;
  justify-content: center;
  &&&& {
    margin: 0;
    white-space: nowrap;
    font-weight: 500;
  }
`;

class ButtonsControl extends BaseControl<
  ButtonControlProps,
  ButtonControlState
> {
  state = {
    isLoading: false,
  };

  onCTAClick = (type: string) => {
    let _value = this.props.propertyValue || 0;
    if (_.isUndefined(this.props.propertyValue)) {
      _value = 0;
    }

    switch (type) {
      case DynamicLayerZIndex.LAYER_BOTTOM:
        _value = -1000;
        break;
      case DynamicLayerZIndex.LAYER_TOP:
        _value = 1000;
        break;
      case DynamicLayerZIndex.LAYER_DOWN:
        _value -= 1;
        break;
      case DynamicLayerZIndex.LAYER_UP:
        _value += 1;
        break;
      default:
        _value = 0;
        break;
    }
    this.updateProperty(this.props.propertyName, _value);
  };

  enableLoading = () => this.setState({ isLoading: true });
  disableLoading = () => this.setState({ isLoading: false });

  render() {
    const { isDisabled, options, propertyValue, widgetProperties } = this.props;
    return (
      <div>
        <Tooltip content={propertyValue}>
          <div className="flex gap-1.5 items-center">
            {options.map((btn: any) => {
              return (
                <StyledButton
                  category={Category.secondary}
                  disabled={isDisabled?.(widgetProperties)}
                  key={btn.value}
                  onClick={() => this.onCTAClick(btn?.value)}
                  size={Size.small}
                  tag="button"
                  text={btn.label}
                  type="button"
                />
              );
            })}

            <StyledRefreshIcon
              key={"reset"}
              onClick={() => this.onCTAClick("reset")}
              size={Size.small}
            />
          </div>
        </Tooltip>
      </div>
    );
  }

  static getControlType() {
    return "BUTTONS";
  }
}

export default ButtonsControl;
