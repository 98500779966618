import React from "react";
import type { ControlProps } from "./BaseControl";
import BaseControl from "./BaseControl";
import { generateReactKey } from "utils/generators";
import { getNextEntityName } from "utils/AppsmithUtils";
import orderBy from "lodash/orderBy";
import isString from "lodash/isString";
import isUndefined from "lodash/isUndefined";
import { DraggableListControl } from "pages/Editor/PropertyPane/DraggableListControl";
import { DraggableListCard } from "components/propertyControls/DraggableListCard";
import { Button } from "design-system";

interface State {
  focusedIndex: number | null;
}

class SegmentedItemsControl extends BaseControl<ControlProps, State> {
  constructor(props: ControlProps) {
    super(props);

    this.state = {
      focusedIndex: null,
    };
  }

  componentDidUpdate(prevProps: ControlProps): void {
    //on adding a new column last column should get focused
    if (
      prevProps.propertyValue &&
      this.props.propertyValue &&
      Object.keys(prevProps.propertyValue).length + 1 ===
        Object.keys(this.props.propertyValue).length
    ) {
      this.updateFocus(Object.keys(this.props.propertyValue).length - 1, true);
    }
  }
  updateItems = (items: Array<Record<string, any>>) => {
    const segmentedItems = items.reduce((obj: any, each: any, index) => {
      obj[each.id] = {
        ...each,
        index,
      };
      return obj;
    }, {});
    this.updateProperty(this.props.propertyName, segmentedItems);
  };

  getSegmentedItems = () => {
    const segmentedItems: Array<{
      id: string;
      label: string;
      disabled: boolean;
      widgetId: string;
    }> =
      isString(this.props.propertyValue) ||
      isUndefined(this.props.propertyValue)
        ? []
        : Object.values(this.props.propertyValue);
    return orderBy(segmentedItems, ["index"], ["asc"]);
  };

  onEdit = (index: number) => {
    const segmentedItems = this.getSegmentedItems();
    const targetMenuItem = segmentedItems[index];
    this.props.openNextPanel({
      index,
      ...targetMenuItem,
      propPaneId: this.props.widgetProperties.widgetId,
    });
  };

  render() {
    return (
      <div className="flex flex-col gap-1">
        <DraggableListControl
          deleteOption={this.deleteOption}
          fixedHeight={370}
          focusedIndex={this.state.focusedIndex}
          itemHeight={45}
          items={this.getSegmentedItems()}
          onEdit={this.onEdit}
          propertyPath={this.props.dataTreePath}
          renderComponent={(props: any) =>
            DraggableListCard({
              ...props,
              isDelete: true,
              placeholder: this.props.placeholder || "选项名称",
            })
          }
          toggleVisibility={this.toggleVisibility}
          updateFocus={this.updateFocus}
          updateItems={this.updateItems}
          updateOption={this.updateOption}
        />

        <Button
          className="self-end t--add-menu-item-btn"
          kind="tertiary"
          onClick={this.addOption}
          startIcon="plus"
        >
          {this.props.buttonText || "新建选项"}
        </Button>
      </div>
    );
  }

  toggleVisibility = (index: number) => {
    const segmentedItems = this.getSegmentedItems();
    const isDisabled = segmentedItems[index].disabled === true ? false : true;
    const updatedSegmentedItems = segmentedItems.map((item, itemIndex) => {
      if (index === itemIndex) {
        return {
          ...item,
          disabled: isDisabled,
        };
      }
      return item;
    });
    this.updateProperty(this.props.propertyName, updatedSegmentedItems);
  };

  deleteOption = (index: number) => {
    const segmentedItemsArray = this.getSegmentedItems();
    if (segmentedItemsArray.length === 1) return;
    const updatedArray = segmentedItemsArray.filter((eachItem: any, i: number) => {
      return i !== index;
    });
    const updatedObj = updatedArray.reduce(
      (obj: any, each: any, index: number) => {
        obj[each.id] = {
          ...each,
          index,
        };
        return obj;
      },
      {},
    );
    this.updateProperty(this.props.propertyName, updatedObj);
  };

  updateOption = (index: number, updatedLabel: string) => {
    const segmentedItemsArray = this.getSegmentedItems();
    const itemId = segmentedItemsArray[index].id;
    this.updateProperty(
      `${this.props.propertyName}.${itemId}.label`,
      updatedLabel,
    );
  };

  addOption = () => {
    let segmentedItems = this.props.propertyValue || [];
    const SegmentedItemsArray = this.getSegmentedItems();
    const newSegmentedItemId = generateReactKey({ prefix: "segmetdItem" });
    const newSegmentedItemValue = generateReactKey({ prefix: "segmetdValue" });
    const newSegmentedItemLabel = getNextEntityName(
      "选项",
      SegmentedItemsArray.map((segmentedItem: any) => segmentedItem.label),
    );
    segmentedItems = {
      ...segmentedItems,
      [newSegmentedItemId]: {
        id: newSegmentedItemId,
        index: SegmentedItemsArray.length,
        label: newSegmentedItemLabel,
        widgetId: generateReactKey(),
        disabled: false,
        value: newSegmentedItemValue,
        visiableItem: true,
        disabledItem: false,
      },
    };

    this.updateProperty(this.props.propertyName, segmentedItems);
  };

  updateFocus = (index: number, isFocused: boolean) => {
    this.setState({ focusedIndex: isFocused ? index : null });
  };

  static getControlType() {
    return "SEGMENTED_ITEMS";
  }
}

export default SegmentedItemsControl;
