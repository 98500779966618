import { Alignment } from "@blueprintjs/core";
import { LabelPosition } from "components/constants";
import { ValidationTypes } from "constants/WidgetValidation";
import { EvaluationSubstitutionType } from "entities/DataTree/dataTreeFactory";
import { isAutoLayout } from "layoutSystems/autolayout/utils/flexWidgetUtils";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";
import {
  DataTypesHelperText,
  FileDataTypes,
} from "widgets/components/AntdUpload/transformFile";
import {
  maxCountsValidation,
  maxValueValidation,
  minValueValidation,
} from "../../validations";
import type { AntdUploadButtonWidgetProps } from "../index";

const CSV_ARRAY_LABEL = "Array of Objects (CSV, XLS(X), JSON, TSV)";

const ARRAY_CSV_HELPER_TEXT = `注意：非 csv 类型文件数据都是空值，组件中使用大文件可能会让应用变得卡顿`;

export default [
  {
    sectionName: "属性",
    children: [
      {
        propertyName: "allowedFileTypes",
        helpText: "限制那些类型的文件可以上传",
        label: "支持文件类型",
        controlType: "DROP_DOWN",
        isMultiSelect: true,
        placeholderText: "选择文件类型",
        options: [
          {
            label: "任意文件类型",
            value: "*",
          },
          {
            label: "图片",
            value: "image/*",
          },
          {
            label: "视频",
            value: "video/*",
          },
          {
            label: "音频",
            value: "audio/*",
          },
          {
            label: "文本",
            value: "text/*",
          },
          {
            label: "Word文档",
            value: ".doc",
          },
          {
            label: "JPEG",
            value: "image/jpeg",
          },
          {
            label: "PNG",
            value: ".png",
          },
        ],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            unique: true,
            children: {
              type: ValidationTypes.TEXT,
            },
          },
        },
        evaluationSubstitutionType: EvaluationSubstitutionType.SMART_SUBSTITUTE,
      },
      {
        helpText: "设置文件读取数据格式",
        propertyName: "fileDataType",
        label: "数据格式",
        controlType: "DROP_DOWN",
        helperText: (props: AntdUploadButtonWidgetProps) => {
          const fileDataType = props.fileDataType || "none";
          return DataTypesHelperText[fileDataType];
        },
        options: [
          {
            label: "直接传递 File 对象",
            value: FileDataTypes.NONE,
          },
          {
            label: FileDataTypes.BASE64,
            value: FileDataTypes.BASE64,
          },
          {
            label: FileDataTypes.BINARY,
            value: FileDataTypes.BINARY,
          },
          {
            label: FileDataTypes.TEXT,
            value: FileDataTypes.TEXT,
          },
          {
            label: FileDataTypes.ARRAY_BUFFER,
            value: FileDataTypes.ARRAY_BUFFER,
          },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
      },
      {
        propertyName: "maxNumFiles",
        label: "最大上传数量",
        helpText: "设置最多上传多少个文件, 若不设置则不限制",
        controlType: "INPUT_TEXT",
        placeholderText: "最大上传文件数量",
        inputType: "INTEGER",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.FUNCTION,
          params: {
            fn: maxCountsValidation,
            expected: {
              type: "number",
              example: ``,
              autocompleteDataType: AutocompleteDataType.NUMBER,
            },
          },
        },
      },
      {
        propertyName: "directory",
        label: "是否支持文件夹",
        helpText: "控制是否支持上传文件夹",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "fileListVisible",
        label: "是否显示上传列表",
        helpText: "控制上传文件列表的显示/隐藏",
        defaultValue: true,
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
    ],
  },
  {
    sectionName: "标签",
    children: [
      {
        propertyName: "buttonText",
        label: "组件文本",
        controlType: "INPUT_TEXT",
        helpText: "设置按钮文本",
        placeholderText: "按钮文本",
        inputType: "TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "label",
        label: "标签",
        controlType: "INPUT_TEXT",
        helpText: "设置按钮标签",
        placeholderText: "标签",
        inputType: "TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        helpText: "设置组件标签位置",
        propertyName: "labelPosition",
        label: "位置",
        controlType: "ICON_TABS",
        fullWidth: false,
        options: [
          { label: "左", value: LabelPosition.Left },
          { label: "上", value: LabelPosition.Top },
          { label: "自动", value: LabelPosition.Auto },
        ],
        hidden: isAutoLayout,
        defaultValue: LabelPosition.Top,
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        helpText: "设置组件标签的对齐方式",
        propertyName: "labelAlignment",
        label: "对齐",
        controlType: "LABEL_ALIGNMENT_OPTIONS",
        fullWidth: false,
        options: [
          {
            startIcon: "align-left",
            value: Alignment.LEFT,
          },
          {
            startIcon: "align-right",
            value: Alignment.RIGHT,
          },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (props: AntdUploadButtonWidgetProps) =>
          props.labelPosition !== LabelPosition.Left,
        dependencies: ["labelPosition"],
      },
      {
        propertyName: "labelWidth",
        label: "宽度（所占列数）",
        helpText: "设置组件标签占用的列数",
        controlType: "NUMERIC_INPUT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        min: 0,
        validation: {
          type: ValidationTypes.NUMBER,
          params: {
            natural: true,
          },
        },
        hidden: (props: AntdUploadButtonWidgetProps) =>
          props.labelPosition !== LabelPosition.Left,
        dependencies: ["labelPosition"],
      },
      {
        propertyName: "tooltip",
        label: "提示文本",
        controlType: "INPUT_TEXT",
        helpText: "设置提示信息",
        placeholderText: "添加提示信息",
        inputType: "TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
  {
    sectionName: "校验",
    children: [
      {
        propertyName: "isRequired",
        label: "必填",
        helpText: "强制用户填写",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "maxFileSize",
        helpText: "设置每个上传文件大小的上限，若不设置则不限制",
        label: "最大上传大小 (Mb)",
        controlType: "INPUT_TEXT",
        placeholderText: "文件最大值(单位:Mb)",
        inputType: "INTEGER",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.FUNCTION,
          params: {
            fn: maxValueValidation,
            expected: {
              type: "number",
              example: ``,
              autocompleteDataType: AutocompleteDataType.NUMBER,
            },
          },
        },
      },
      {
        propertyName: "minFileSize",
        helpText: "设置每个上传文件大小的下限",
        label: "最小上传大小 (Mb)",
        controlType: "INPUT_TEXT",
        placeholderText: "文件最小值(单位:Mb)",
        inputType: "INTEGER",
        isBindProperty: true,
        isTriggerProperty: false,
        validationMessage: "最小上传大小必须小于最大上传大小",
        validation: {
          type: ValidationTypes.FUNCTION,
          params: {
            fn: minValueValidation,
            expected: {
              type: "number",
              example: `0`,
              autocompleteDataType: AutocompleteDataType.NUMBER,
            },
          },
        },
        // validation: {
        //   type: ValidationTypes.NUMBER,
        //   params: {
        //     min: 0,
        //     max: 200,
        //     default: 0,
        //     passThroughOnZero: false,
        //   },
        // },
      },
    ],
  },
  {
    sectionName: "属性",
    children: [
      {
        propertyName: "labelTooltip",
        label: "标签提示",
        controlType: "INPUT_TEXT",
        helpText: "设置标签提示信息",
        placeholderText: "添加标签提示信息",
        inputType: "TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        helpText: "控制组件的冒号",
        propertyName: "showColon",
        label: "显示冒号",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "isVisible",
        label: "是否显示",
        helpText: "控制组件的显示/隐藏",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "isDisabled",
        label: "禁用",
        helpText: "让组件不可交互",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "animateLoading",
        label: "加载时显示动画",
        controlType: "SWITCH",
        helpText: "组件依赖的数据加载时显示加载动画",
        defaultValue: false,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        helpText: "文件成功上传后，清空当前上传列表的内容",
        propertyName: "resetOnSuccess",
        label: "提交成功后重置",
        defaultValue: false,
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
    ],
  },
  {
    sectionName: "事件",
    children: [
      {
        helpText:
          "用户上传文件后触发，且每个文件都会触发一次，当前上传文件 File 存储在 AntdUploadImageWidget.file 中；已上传文件列表存储在 AntdUploadImageWidget.fileList 中；转化后的文件格式存储在 AntdUploadImageWidget.file.data 及 fileList每一项的 data 中；自定义方法需设置返回值 {success: boolean} 以判断是否上传成功",
        propertyName: "onUpload",
        label: "onUpload",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
      {
        helpText:
          "用户选中删除文件触发，当前删除文件 File 存储在 AntdUploadImageWidget.file 中；自定义方法需设置返回值 {success: boolean} 以判断是否删除成功",
        propertyName: "onRemove",
        label: "onRemove",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
    ],
  },
];
