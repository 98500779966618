import PositionedContainer from "components/designSystems/appsmith/PositionedContainer";
import React from "react";
import type { BaseWidgetProps } from "widgets/BaseWidgetHOC/withBaseWidgetHOC";
import { DynamicHeight } from "utils/WidgetFeatures";

export const PositionedComponentLayer = (props: BaseWidgetProps) => {
  let componentHeight = props.componentHeight;
  // if (props.type === "DIVIDER_WIDGET") {
  //   console.log("DIVIDER_WIDGET1", props.dynamicHeight)
  //   componentHeight = 10;
  // }
  return (
    <PositionedContainer
      componentHeight={componentHeight}
      componentWidth={props.componentWidth}
      focused={props.focused}
      isDisabled={props.isDisabled}
      isVisible={props.isVisible}
      leftColumn={props.leftColumn}
      noContainerOffset={props.noContainerOffset}
      parentColumnSpace={props.parentColumnSpace}
      parentId={props.parentId}
      parentRowSpace={props.parentRowSpace}
      ref={props.wrapperRef}
      resizeDisabled={props.resizeDisabled}
      selected={props.selected}
      topRow={props.topRow}
      widgetId={props.widgetId}
      widgetName={props.widgetName}
      widgetType={props.type}
      dynamicHeight={props.dynamicHeight}
    >
      {props.children}
    </PositionedContainer>
  );
};
