import { ValidationTypes } from "constants/WidgetValidation";
import { ButtonVariantTypes } from "components/constants";
import {
  ColumnTypes,
  type TableWidgetProps,
} from "widgets/TableWidgetV3/constants";
import { getBasePropertyPath, hideByColumnType } from "../../propertyUtils";
import { get } from "lodash";

export default {
  sectionName: "属性",
  children: [
    {
      propertyName: "buttonVariant",
      label: "按钮类型",
      controlType: "ICON_TABS",
      fullWidth: true,
      helpText: "设置按钮类型",
      options: [
        {
          label: "主按钮",
          value: ButtonVariantTypes.PRIMARY,
        },
        {
          label: "次级按钮",
          value: ButtonVariantTypes.SECONDARY,
        },
        {
          label: "文本按钮",
          value: ButtonVariantTypes.TERTIARY,
        },
        {
          label: "链接按钮",
          value: ButtonVariantTypes.LINK,
        },
      ],
      defaultValue: ButtonVariantTypes.PRIMARY,
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      dependencies: ["primaryColumns", "columnOrder"],
      hidden: (props: TableWidgetProps, propertyPath: string) => {
        const baseProperty = getBasePropertyPath(propertyPath);
        const columnType = get(props, `${baseProperty}.columnType`, "");
        return columnType !== ColumnTypes.ACTION_GROUP;
      },
      validation: {
        type: ValidationTypes.TEXT,
        params: {
          allowedValues: [
            ButtonVariantTypes.PRIMARY,
            ButtonVariantTypes.SECONDARY,
            ButtonVariantTypes.TERTIARY,
            ButtonVariantTypes.LINK,
          ],
          default: ButtonVariantTypes.PRIMARY,
        },
      },
    },
  ],
};
