import React, { Suspense } from "react";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import { ValidationTypes } from "constants/WidgetValidation";
import { TRANSITION_TYPES } from "../constants";
import DigitalFlopComponent from "../component";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import IconSVG from "../icon.svg";
import type {
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";
import { Skeleton } from "antd";
import { retryPromise } from "utils/AppsmithUtils";

const LazyDigitalFlopComponent = React.lazy(async () =>
  retryPromise(async () => import("../component")),
);
class DigitalFlopWidget extends BaseWidget<
  DigitalFlopWidgetProps,
  WidgetState
> {
  static type = "DIGITALFLOP_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return {
      name: "数字翻牌", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      // isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
      isScreen: true,
      tags: [WIDGET_TAGS.SCREEN],
    };
  }

  static getDefaults(): WidgetDefaultProps {
    return {
      widgetName: "DigitalFlop",
      rows: 10,
      columns: 12,
      version: 1,
      number: [90999],
      toFixed: 2,
      content: "{nt}个",
      rowGap: 0,
      style: {
        fontSize: 30,
        fill: "#3de7c9",
      },
      animationCurve: "easeOutCubic",
      textAlign: "center",
      animationFrame: 50,
      wait: 500,
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "300px",
            };
          },
        },
      ],
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "数据",
        children: [
          {
            helpText:
              "number中的元素将被用于替换content内容模版中的{nt}标记，其替换顺序与模版标记的顺序一一对",
            propertyName: "number",
            label: "数字数值",
            controlType: "INPUT_TEXT",
            placeholderText: "[1,2,3]",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.NUMBER,
                },
              },
            },
          },
          {
            propertyName: "content",
            label: "内容模板",
            controlType: "INPUT_TEXT",
            placeholderText: "[1,2,3]",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "toFixed",
            label: "小数位数",
            controlType: "INPUT_TEXT",
            placeholderText: "0",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.NUMBER,
            },
          },

          {
            propertyName: "animationCurve",
            label: "动效曲线",
            controlType: "DROP_DOWN",
            placeholderText: "easeOutCubic",
            defaultValue: "easeOutCubic",
            isBindProperty: true,
            isTriggerProperty: false,
            options: TRANSITION_TYPES.map((it) => ({ value: it, label: it })),
            isJSConvertible: true,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            helpText: "等待多少毫秒开始动画",
            propertyName: "wait",
            label: "等待毫秒",
            controlType: "INPUT_TEXT",
            placeholderText: "500",
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: 500,
            validation: {
              type: ValidationTypes.NUMBER,
            },
          },
          {
            helpText: "用于配置动画过程的帧数即动画时长",
            propertyName: "animationFrame",
            label: "动效帧数",
            controlType: "INPUT_TEXT",
            placeholderText: "50",
            isBindProperty: true,
            isTriggerProperty: false,
            defaultValue: 50,
            validation: {
              type: ValidationTypes.NUMBER,
            },
          },
          {
            propertyName: "colourways",
            label: "配色",
            controlType: "COLOR_SET_PICKER",
            defaultValue: "set1",
            isBindProperty: true,
            isTriggerProperty: false,
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "样式",
        children: [
          {
            propertyName: "textAlign",
            label: "水平对齐方式",
            fullWidth: true,
            defaultValue: "center",
            controlType: "ICON_TABS",
            options: [
              {
                label: "左对齐",
                value: "left",
              },
              {
                label: "居中",
                value: "center",
              },
              {
                label: "右对齐",
                value: "right",
              },
            ],
            columns: 3,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "rowGap",
            label: "行间距",
            controlType: "INPUT_TEXT",
            placeholderText: "0",
            isBindProperty: true,
            defaultValue: 0,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.NUMBER,
            },
          },
          {
            propertyName: "style",
            label: "样式配置",
            controlType: "INPUT_TEXT",
            // placeholderText: "[1,2,3]",
            default: {
              fontSize: 30,
              fill: "#fff",
            },
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.OBJECT,
            },
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const {
      animationCurve,
      animationFrame,
      content,
      number,
      rowGap,
      style,
      textAlign,
      toFixed,
      wait,
    } = this.props;
    const childProps = {
      number,
      content,
      toFixed,
      textAlign,
      rowGap,
      style,
      animationCurve,
      animationFrame,
      wait,
    };
    return (
      <Suspense fallback={<Skeleton />}>
        <LazyDigitalFlopComponent {...childProps} />
      </Suspense>
    );
  }
}

export interface DigitalFlopWidgetProps extends WidgetProps {
  number: number;
  content: string;
  toFixed: number;
  textAlign: string;
  rowGap: number;
  style: any;
  animationCurve: string;
  animationFrame: number;
  // wait: number;
}

export default DigitalFlopWidget;
