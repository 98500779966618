import React, { Suspense } from "react";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import RealtimeClockComponent from "../component";
import { ValidationTypes } from "constants/WidgetValidation";
import IconSVG from "../icon.svg";
import type {
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import { LayoutDirection } from "layoutSystems/common/utils/constants";
import { Skeleton } from "antd";
import { retryPromise } from "utils/AppsmithUtils";


const LazyRealtimeClockComponent = React.lazy(async () =>
  retryPromise(async () => import("../component")),
);
class RealtimeClockWidget extends BaseWidget<
  RealtimeClockWidgetProps,
  WidgetState
> {
  static type = "REALTIMECLOCK_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return {
      name: "时钟", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isScreen: true,
      tags: [WIDGET_TAGS.SCREEN],
      searchTags: ["time", "clock", "realtime"],
    };
  }

  static getDefaults(): WidgetDefaultProps {
    return {
      widgetName: "RealtimeClock",
      version: 1,
      rows: 1,
      columns: 22,
      showDate: true,
      dateTextSize: "1rem",
      timeTextSize: "1rem",
      timeTextColor: "#000000",
      dateTextColor: "#000000",
      iconColor: "#000000",
      showTime: true,
      direction: LayoutDirection.Horizontal, // 横向
      showClockIcon: false, // 展示时钟图标
      showWeek: false,
      dateFormatter: "YYYY-MM-DD",
      timeFormatter: "HH:mm:ss",
      align: "center",
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "36px",
            };
          },
        },
      ],
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "格式",
        children: [
          {
            // helpText: "显示日期",
            propertyName: "showDate",
            label: "显示日期",
            controlType: "SWITCH",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "dateFormatter",
            label: "日期格式化",
            controlType: "INPUT_TEXT",
            defaultValue: "YYYY-MM-DD",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            // helpText: "显示时间",
            propertyName: "showTime",
            label: "显示时间",
            controlType: "SWITCH",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "timeFormatter",
            label: "时间格式化",
            controlType: "INPUT_TEXT",
            defaultValue: "HH:mm:ss",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            // helpText: "显示星期",
            propertyName: "showWeek",
            label: "显示星期",
            controlType: "SWITCH",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            // helpText: "显示图标",
            propertyName: "showClockIcon",
            label: "显示图标",
            controlType: "SWITCH",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "文本和图标",
        children: [
          {
            propertyName: "direction",
            label: "排列方向",
            controlType: "DROP_DOWN",
            defaultValue: LayoutDirection.Horizontal,
            options: [
              {
                label: "水平",
                value: LayoutDirection.Horizontal,
              },
              {
                label: "垂直",
                value: LayoutDirection.Vertical,
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "align",
            label: "文本对齐",
            fullWidth: true,
            controlType: "ICON_TABS",
            options: [
              {
                label: "左对齐",
                value: "left",
              },
              {
                label: "居中",
                value: "center",
              },
              {
                label: "右对齐",
                value: "right",
              },
            ],
            columns: 3,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "iconColor",
            label: "图标颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
        ],
      },
      {
        sectionName: "日期",
        children: [
          {
            propertyName: "dateTextSize",
            label: "字体大小",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "dateTextColor",
            label: "字体颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
        ],
      },
      {
        sectionName: "时间",
        children: [
          {
            propertyName: "timeTextSize",
            label: "字体大小",
            controlType: "DROP_DOWN",
            defaultValue: "0.875rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "timeTextColor",
            label: "字体颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const {
      align,
      dateFormatter,
      dateTextColor,
      dateTextSize,
      direction,
      hoursSet,
      iconColor, // 横向
      showClockIcon, // 展示时钟图标
      showDate,
      showTime,
      showWeek,
      timeFormatter,
      timeTextColor,
      timeTextSize,
    } = this.props;
    const childProps = {
      showDate,
      dateTextSize,
      timeTextSize,
      timeTextColor,
      dateTextColor,
      showTime,
      direction, // 横向
      showClockIcon, // 展示时钟图标
      showWeek,
      hoursSet,
      iconColor,
      dateFormatter,
      timeFormatter,
      align,
    };
    return (
      <Suspense fallback={<Skeleton />}>
        <LazyRealtimeClockComponent {...childProps} />
      </Suspense>
    );
  }
}

export interface RealtimeClockWidgetProps extends WidgetProps {
  showDate: boolean;
  dateTextSize: string;
  timeTextSize: string;
  timeTextColor: string;
  dateTextColor: string;
  showTime: boolean;
  direction: LayoutDirection; // 横向
  showClockIcon: boolean; // 展示时钟图标
  showWeek: boolean;
  hoursSet: number;
  iconColor: string;
  dateFormatter: string;
  timeFormatter: string;
  align: string;
}

export default RealtimeClockWidget;
