import React, { useCallback } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { Tabs, TabsList, Tab, TabPanel } from "design-system";
import { getSelectedPropertyTabIndex } from "selectors/editorContextSelectors";
import { setSelectedPropertyTabIndex } from "actions/editorContextActions";
import type { AppState } from "ee/reducers";
import { ApplicationPayload } from "ee/constants/ReduxActionConstants";
import { getCurrentApplication } from "ee/selectors/applicationSelectors";
import { getWidgetByID } from "sagas/selectors";
import AddInputs from "./ModulePane";

interface PropertyPaneTabProps {
  styleComponent: JSX.Element | null;
  contentComponent: JSX.Element | null;
  isPanelProperty?: boolean;
  panelPropertyPath?: string;
}

const tabs = ["content", "style"];

const StyledTabs = styled(Tabs)`
  > [role="tabpanel"] {
    margin-top: 0;
  }

  > [role="tablist"] {
    position: sticky;
    top: 74px;
    z-index: 3;
    background: var(--ads-v2-color-white);
    overflow: hidden;
    padding: var(--ads-v2-spaces-1) var(--ads-v2-spaces-4);
  }
`;

export function PropertyPaneTab(props: PropertyPaneTabProps) {
  const dispatch = useDispatch();
  const selectedIndex = useSelector((state: AppState) =>
    getSelectedPropertyTabIndex(state, props.panelPropertyPath),
  );
  const currentApplication: ApplicationPayload = useSelector(
    getCurrentApplication,
  )!;
  const selectedWidget = useSelector(
    getWidgetByID(props.contentComponent?.props.id || ""),
  );

  const setSelectedIndex = useCallback(
    (index: number) => {
      dispatch(setSelectedPropertyTabIndex(index, props.panelPropertyPath));
    },
    [dispatch, props.panelPropertyPath],
  );
  const onValueChange = useCallback(
    (value) => {
      setSelectedIndex(tabs.indexOf(value) || 0);
    },
    [setSelectedIndex],
  );
  return (
    <StyledTabs onValueChange={onValueChange} value={tabs[selectedIndex]}>
      <TabsList>
        {props.contentComponent && <Tab value={tabs[0]}>内容</Tab>}
        {/* {props.styleComponent &&
          ((currentApplication.type === "app" && !selectedWidget?.isModule) ||
            currentApplication.type === "module") && (
            <Tab value={tabs[1]}>样式</Tab>
          )} */}
        {props.styleComponent && <Tab value={tabs[1]}>样式</Tab>}
        {/* {props.contentComponent &&
          currentApplication.type === "module" &&
          selectedWidget.parentId === "0" && <Tab value={tabs[2]}>设置</Tab>} */}
      </TabsList>
      {/* {props.contentComponent && (
        <TabPanel value={tabs[0]}>
          {currentApplication.type === "app" &&
          selectedWidget?.isModule &&
          selectedWidget.parentId === "0" ? (
            <div>asdasd</div>
          ) : (
            props.contentComponent
          )}
        </TabPanel>
      )} */}
      {props.contentComponent && (
        <TabPanel value={tabs[0]}>
          {props.contentComponent}
        </TabPanel>
      )}
      {props.styleComponent && (
        <TabPanel value={tabs[1]}>{props.styleComponent}</TabPanel>
      )}
      {/* {props.contentComponent &&
        currentApplication.type === "module" &&
        selectedWidget.parentId === "0" && (
          <TabPanel value={tabs[3]}>
            <AddInputs />
          </TabPanel>
        )} */}
    </StyledTabs>
  );
}
