import { getIconParams } from "utils/widgetIcon";
import type { ColumnProperties, ThemeProp } from "../Constants";
import { Alignment, Icon, Spinner } from "@blueprintjs/core";
import AntdIcon from "components/common/AntdIcon";
import styled from "styled-components";
import {
  ButtonVariantTypes,
  type ButtonPlacement,
  type ButtonVariant,
} from "components/constants";
import _ from "lodash";
import {
  calculateHoverColor,
  getComplementaryGrayscaleColor,
  getCustomJustifyContent,
} from "widgets/WidgetUtils";
import { useState } from "react";
import { ConfigProvider, Button as AntdButton, Dropdown } from "antd";
import { addOverlayEffect } from "pages/utils";
import { CellWrapper } from "../TableStyledWrappers";

interface WrapperStyleProps {
  borderRadius?: string;
  boxShadow?: string;
  buttonVariant?: ButtonVariant;
}

const darkenBackgroundColor = (color: string, amount: number) => {
  const darken = (c: number) => Math.max(c - amount * 255, 0);
  const colorValue = color.slice(1); // Remove '#'
  const num = parseInt(colorValue, 16);
  const r = darken((num >> 16) & 255);
  const g = darken((num >> 8) & 255);
  const b = darken(num & 255);
  return `rgb(${r}, ${g}, ${b})`;
};

const ButtonGroupWrapper = styled.div<ThemeProp & WrapperStyleProps>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: not-allowed;
  gap: 6px;
  padding: 4px;
`;

const StyledText = styled.pre<{
  disabled?: boolean;
  iconName?: any;
  iconAlign?: string;
  textColor?: string;
  hoverTextColor?: string;
}>`
  color: ${({ disabled, textColor }) =>
    disabled ? "rgba(0, 0, 0, 0.25)" : textColor};
  margin-left: ${({ iconName, iconAlign }) =>
    iconName && iconAlign === "left" ? "8px" : 0};
  margin-right: ${({ iconName, iconAlign }) =>
    iconName && iconAlign === "right" ? "8px" : 0};
  &:hover {
    // color: ${(props) => props.hoverTextColor} !important;
  }
`;

const CustomMenuItem = styled.div<{
  backgroundColor?: string;
  textColor?: string;
  key: string;
  disabled?: boolean;
  isVisible?: boolean;
  hoverBackgroundColor?: string;
  hoverTextColor?: string;
}>`
  background-color: ${(props) =>
    !props.disabled ? props.backgroundColor : "transparent"};
  padding: 8px 10px !important;
  border-radius: 4px;
  transition: background-color 0.3s;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: ${(props) =>
      !props.disabled
        ? (props.hoverBackgroundColor && props.backgroundColor) ||
          props.hoverBackgroundColor
          ? props.hoverBackgroundColor
          : props.backgroundColor
            ? darkenBackgroundColor(props.backgroundColor, 0.1)
            : "rgba(0, 0, 0, 0.04)"
        : "transparent"};
    border-radius: 4px;
    ${StyledText} {
      color: ${(props) =>
        !props.disabled
          ? props.hoverTextColor
          : "rgba(0, 0, 0, 0.25)"} !important;
    }
  }
`;

const StyledButtonContent = styled.div<{
  iconAlign: string;
  placement?: ButtonPlacement;
}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${({ placement }) => getCustomJustifyContent(placement)};
  flex-direction: ${({ iconAlign }) =>
    iconAlign === Alignment.RIGHT ? "row-reverse" : "row"};
  padding: 8px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  flex: 1 1 auto;
  .ant-btn-icon {
    display: inline-block;
    width: 100%;
  }
`;

interface ButtonData {
  id?: string;
  type?: string;
  label?: string;
  iconName?: any;
}

const ActionGroupCell = (props: ActionGroupCellProps) => {
  const { accentColor, cellBackground, column, isDisabled, buttonVariant } =
    props;
  const buttonVariantType = buttonVariant as ButtonVariantTypes;
  const AntdButtonStyleTypes: Record<any, string> = {
    PRIMARY: "primary",
    SECONDARY: "default",
    TERTIARY: "text",
    LINK: "link",
  };
  const [loadedBtnId, setLoadedBtnId] = useState<string>("");

  const onButtonClick = (onClick: string | undefined, buttonId: string) => {
    if (onClick) {
      handleActionStart(buttonId);
      props.onCommandClick(onClick, () => handleActionComplete());
    }
  };

  const handleActionStart = (id: string) => {
    setLoadedBtnId(id);
  };

  const handleActionComplete = () => {
    setLoadedBtnId("");
  };

  const getButtonData = (
    groupButtons: Record<string, GroupButtonProps>,
  ): ButtonData[] => {
    const buttonData = Object.keys(groupButtons).reduce(
      (acc: ButtonData[], id) => {
        return [
          ...acc,
          {
            id,
            type: groupButtons[id].buttonType,
            label: groupButtons[id].label,
            iconName: groupButtons[id].iconName,
          },
        ];
      },
      [],
    );

    return buttonData as ButtonData[];
  };

  const getOnClick = (button: GroupButtonProps) => {
    if (!button.onClick) return;
    return () => {
      onButtonClick(button.onClick, button.id);
    };
  };

  const renderLabelWithIcon = (
    label?: any,
    iconName?: any,
    iconColor?: string,
    iconAlign?: string,
    onClick?: string | undefined,
    key?: string,
    disabled?: boolean,
    textColor?: string,
    hoverTextColor?: string,
  ) => {
    const iconObj = getIconParams(iconName);
    const iconElement =
      iconObj.type === "appsmith" ? (
        <Icon
          icon={iconObj.iconName}
          style={{
            color: `${disabled ? "rgba(0, 0, 0, 0.25)" : iconColor}`,
          }}
        />
      ) : (
        <AntdIcon
          type={iconObj.iconName ?? ""}
          style={{
            color: `${disabled ? "rgba(0, 0, 0, 0.25)" : iconColor}`,
            fontSize: "18px",
          }}
        />
      );
    if (iconObj.iconName) {
      return iconAlign === Alignment.RIGHT ? (
        <div
          className="flex items-center justify-between"
          onClick={() => {
            if (disabled) {
              return;
            }
            onButtonClick(onClick, key as string);
          }}
        >
          <StyledText
            disabled={disabled}
            iconName={iconObj.iconName}
            iconAlign={iconAlign || "left"}
            textColor={textColor}
            hoverTextColor={hoverTextColor}
          >
            {label}
          </StyledText>
          {iconElement}
        </div>
      ) : (
        <div
          className="flex items-center"
          onClick={() => {
            if (disabled) {
              return;
            }
            onButtonClick(onClick, key as string);
          }}
        >
          {iconElement}
          <StyledText
            disabled={disabled}
            iconName={iconObj.iconName}
            iconAlign={iconAlign || "left"}
            textColor={textColor}
            hoverTextColor={hoverTextColor}
          >
            {label}
          </StyledText>
        </div>
      );
    }

    return (
      <div
        onClick={() => {
          if (disabled) {
            return;
          }
          onButtonClick(onClick, key as string);
        }}
      >
        <StyledText
          disabled={disabled}
          iconName={iconObj.iconName}
          iconAlign={iconAlign || "left"}
          textColor={textColor}
          hoverTextColor={hoverTextColor}
        >
          {label}
        </StyledText>
      </div>
    );
  };

  let buttonsItems = Object.keys(column?.columnProperties.groupButtons)
    .map((itemKey) => column?.columnProperties.groupButtons[itemKey])
    .filter((item) => item.isVisible === true);

  return (
    <CellWrapper cellBackground={cellBackground}>
      <ButtonGroupWrapper className="t--buttongroup-widget">
        {buttonsItems.map((button) => {
          const isLoading = button.id === loadedBtnId;
          const isButtonDisabled =
            button.isDisabled || isDisabled || !!loadedBtnId || isLoading;
          if (button.buttonType === "MENU" && !isButtonDisabled) {
            const { menuItems, menuPlacement } = button;
            const iconObj = getIconParams(button.iconName);

            if (_.isEmpty(menuItems)) {
              return (
                <ConfigProvider
                  key={button.id}
                  theme={{
                    token: {
                      colorLink: button?.buttonColor || accentColor,
                      colorPrimary: button.buttonColor || accentColor,
                      colorBgTextActive: addOverlayEffect(
                        calculateHoverColor(button.buttonColor || accentColor),
                        0.5,
                      ),
                      colorTextLightSolid:
                        buttonVariant === ButtonVariantTypes.PRIMARY
                          ? getComplementaryGrayscaleColor(
                              button.buttonColor || accentColor,
                            )
                          : addOverlayEffect(
                              calculateHoverColor(
                                button.buttonColor || accentColor,
                              ),
                              0.5,
                            ),
                      colorText:
                        buttonVariant === ButtonVariantTypes.TERTIARY
                          ? addOverlayEffect(
                              calculateHoverColor(
                                button?.buttonColor || accentColor,
                              ),
                              0.2,
                            )
                          : buttonVariant === ButtonVariantTypes.LINK
                            ? button?.buttonColor || accentColor
                            : "initial",
                    },
                    components: {
                      Button: {
                        // fontWeight: 600,
                        textHoverBg: addOverlayEffect(
                          calculateHoverColor(
                            button.buttonColor || accentColor,
                          ),
                          0.8,
                        ),
                        contentFontSize: 15,
                      },
                    },
                  }}
                >
                  <AntdButton
                    disabled={isDisabled}
                    type={
                      AntdButtonStyleTypes[
                        buttonVariant === undefined ? "PRIMARY" : buttonVariant
                      ] as
                        | "link"
                        | "dashed"
                        | "text"
                        | "default"
                        | "primary"
                        | undefined
                    }
                    onClick={getOnClick(button)}
                    style={{
                      width: "100%",
                      // height: "100%",
                    }}
                    autoInsertSpace={false}
                    icon={
                      <StyledButtonContent
                        iconAlign={button.iconAlign || "left"}
                        placement={button.placement}
                      >
                        {isLoading ? (
                          <Spinner size={18} />
                        ) : (
                          <>
                            {iconObj.iconName &&
                              (iconObj.type === "appsmith" ? (
                                <Icon icon={iconObj.iconName} />
                              ) : (
                                <AntdIcon
                                  type={iconObj.iconName ?? ""}
                                  style={{ fontSize: `18px` }}
                                />
                              ))}
                            {!!button.label && (
                              <pre
                                style={{
                                  marginLeft:
                                    button.iconName &&
                                    (button.iconAlign || "left") === "left"
                                      ? "8px"
                                      : 0,
                                  marginRight:
                                    button.iconName &&
                                    (button.iconAlign || "left") === "right"
                                      ? "8px"
                                      : 0,
                                }}
                              >
                                {button.label}
                              </pre>
                            )}
                          </>
                        )}
                      </StyledButtonContent>
                    }
                  ></AntdButton>
                </ConfigProvider>
              );
            }
            const sortMenuItems = _.sortBy(menuItems, ["index"]);
            const items = Object.values(sortMenuItems).map((it) => {
              return {
                key: it.id,
                label: <div>{it.label}</div>,
                backgroundColor: it.backgroundColor,
                disabled: it.isDisabled,
                isVisible: it.isVisible,
                iconName: it.iconName,
                textColor: it.textColor,
                iconColor: it.iconColor,
                iconAlign: it.iconAlign,
                onClick: it.onClick,
                hoverBackgroundColor: it.hoverBackgroundColor,
                hoverTextColor: it.hoverTextColor,
              };
            });
            return (
              <ButtonContainer key={button.id}>
                <ConfigProvider
                  key={button.id}
                  theme={{
                    token: {
                      colorLink: button?.buttonColor || accentColor,
                      colorPrimary: button.buttonColor || accentColor,
                      colorBgTextActive: addOverlayEffect(
                        calculateHoverColor(button.buttonColor || accentColor),
                        0.5,
                      ),
                      colorTextLightSolid:
                        buttonVariant === ButtonVariantTypes.PRIMARY
                          ? getComplementaryGrayscaleColor(
                              button.buttonColor || accentColor,
                            )
                          : addOverlayEffect(
                              calculateHoverColor(
                                button.buttonColor || accentColor,
                              ),
                              0.5,
                            ),
                      colorText:
                        buttonVariant === ButtonVariantTypes.TERTIARY
                          ? addOverlayEffect(
                              calculateHoverColor(
                                button?.buttonColor || accentColor,
                              ),
                              0.2,
                            )
                          : buttonVariant === ButtonVariantTypes.LINK
                            ? button?.buttonColor || accentColor
                            : "initial",
                    },
                    components: {
                      Button: {
                        textHoverBg: addOverlayEffect(
                          calculateHoverColor(
                            button.buttonColor || accentColor,
                          ),
                          0.8,
                        ),
                        contentFontSize: 15,
                      },
                    },
                  }}
                >
                  <Dropdown
                    key={button.id}
                    menu={{ items }}
                    placement={menuPlacement}
                    trigger={["click"]}
                    dropdownRender={(menu) => {
                      return (
                        <div className="ant-dropdown-menu">
                          {items.map((i) => {
                            if (i.isVisible) {
                              return (
                                <CustomMenuItem
                                  key={i.key}
                                  disabled={i.disabled}
                                  backgroundColor={i.backgroundColor}
                                  textColor={i.textColor}
                                  isVisible={i.isVisible}
                                >
                                  {renderLabelWithIcon(
                                    i.label,
                                    i.iconName,
                                    i.iconColor,
                                    i.iconAlign,
                                    i.onClick,
                                    i.key,
                                    i.disabled,
                                    i.textColor,
                                    i.hoverTextColor,
                                  )}
                                </CustomMenuItem>
                              );
                            }
                          })}
                        </div>
                      );
                    }}
                  >
                    <AntdButton
                      disabled={isDisabled}
                      type={
                        AntdButtonStyleTypes[
                          buttonVariant === undefined
                            ? "PRIMARY"
                            : buttonVariant
                        ] as
                          | "link"
                          | "dashed"
                          | "text"
                          | "default"
                          | "primary"
                          | undefined
                      }
                      onClick={getOnClick(button)}
                      style={{
                        width: "100%",
                      }}
                      autoInsertSpace={false}
                      icon={
                        <StyledButtonContent
                          iconAlign={button.iconAlign || "left"}
                          placement={button.placement}
                        >
                          {isLoading ? (
                            <Spinner size={18} />
                          ) : (
                            <>
                              {iconObj?.iconName &&
                                (iconObj.type === "appsmith" ? (
                                  <Icon icon={iconObj?.iconName} />
                                ) : (
                                  <AntdIcon
                                    type={iconObj?.iconName ?? ""}
                                    style={{ fontSize: `18px` }}
                                  />
                                ))}
                              {!!button.label && (
                                <pre
                                  style={{
                                    marginLeft:
                                      iconObj.iconName &&
                                      (button.iconAlign || "left") === "left"
                                        ? "8px"
                                        : 0,
                                    marginRight:
                                      iconObj.iconName &&
                                      (button.iconAlign || "left") === "right"
                                        ? "8px"
                                        : 0,
                                  }}
                                >
                                  {button.label}
                                </pre>
                              )}
                            </>
                          )}
                        </StyledButtonContent>
                      }
                    ></AntdButton>
                  </Dropdown>
                </ConfigProvider>
              </ButtonContainer>
            );
          }
          const iconObj = getIconParams(button.iconName);
          return (
            <ButtonContainer key={button.id}>
              <ConfigProvider
                key={button.id}
                theme={{
                  token: {
                    colorPrimary: button?.buttonColor || accentColor,
                    colorLink: button?.buttonColor || accentColor,
                    colorBgTextActive: addOverlayEffect(
                      calculateHoverColor(button?.buttonColor || accentColor),
                      0.5,
                    ),
                    colorTextLightSolid:
                      buttonVariant === ButtonVariantTypes.PRIMARY
                        ? getComplementaryGrayscaleColor(
                            button?.buttonColor || accentColor,
                          )
                        : addOverlayEffect(
                            calculateHoverColor(
                              button?.buttonColor || accentColor,
                            ),
                            0.5,
                          ),
                    colorText:
                      buttonVariant === ButtonVariantTypes.TERTIARY
                        ? addOverlayEffect(
                            calculateHoverColor(
                              button?.buttonColor || accentColor,
                            ),
                            0.2,
                          )
                        : buttonVariant === ButtonVariantTypes.LINK
                          ? button?.buttonColor || accentColor
                          : "initial",
                  },
                  components: {
                    Button: {
                      textHoverBg: addOverlayEffect(
                        calculateHoverColor(button?.buttonColor || accentColor),
                        0.8,
                      ),
                      contentFontSize: 15,
                    },
                  },
                }}
              >
                <AntdButton
                  disabled={isButtonDisabled}
                  type={
                    AntdButtonStyleTypes[
                      buttonVariant === undefined ? "PRIMARY" : buttonVariant
                    ] as
                      | "link"
                      | "dashed"
                      | "text"
                      | "default"
                      | "primary"
                      | undefined
                  }
                  onClick={getOnClick(button)}
                  style={{
                    width: "100%",
                  }}
                  icon={
                    <StyledButtonContent
                      iconAlign={button.iconAlign || "left"}
                      placement={button.placement}
                    >
                      {isLoading ? (
                        <Spinner size={18} />
                      ) : (
                        <>
                          {iconObj.iconName &&
                            (iconObj.type === "appsmith" ? (
                              <Icon icon={iconObj.iconName} />
                            ) : (
                              <AntdIcon
                                type={iconObj.iconName ?? ""}
                                style={{ fontSize: `18px` }}
                              />
                            ))}
                          {!!button.label && (
                            <pre
                              style={{
                                marginLeft:
                                  button.iconName &&
                                  (button.iconAlign || "left") === "left"
                                    ? "8px"
                                    : 0,
                                marginRight:
                                  button.iconName &&
                                  (button.iconAlign || "left") === "right"
                                    ? "8px"
                                    : 0,
                              }}
                            >
                              {button.label}
                            </pre>
                          )}
                        </>
                      )}
                    </StyledButtonContent>
                  }
                ></AntdButton>
              </ConfigProvider>
            </ButtonContainer>
          );
        })}
      </ButtonGroupWrapper>
    </CellWrapper>
  );
};

interface ActionGroupCellProps {
  accentColor: string;
  cellBackground?: string;
  isDisabled?: boolean;
  column: any;
  widgetId: string;
  onCommandClick: (dynamicTrigger: string, onComplete: () => void) => void;
  buttonVariant: string;
}

interface GroupButtonProps {
  widgetId: string;
  id: string;
  index: number;
  isVisible?: boolean;
  isDisabled?: boolean;
  label?: string;
  buttonType?: string;
  buttonColor?: string;
  iconName?: any;
  iconAlign?: Alignment;
  placement?: ButtonPlacement;
  onClick?: string;
  menuPlacement?:
    | "topLeft"
    | "topCenter"
    | "topRight"
    | "bottomLeft"
    | "bottomCenter"
    | "bottomRight"
    | "top"
    | "bottom"
    | undefined;
  menuItems: Record<
    string,
    {
      widgetId: string;
      id: string;
      index: number;
      isVisible?: boolean;
      isDisabled?: boolean;
      label?: string;
      backgroundColor?: string;
      textColor?: string;
      iconName?: any;
      iconColor?: string;
      iconAlign?: Alignment;
      onClick?: string;
      hoverTextColor?: string;
      hoverBackgroundColor?: string;
      menuPlacement?: string;
    }
  >;
}

export default ActionGroupCell;
