/** 形态变体 */
export enum ShapeType {
  /** 线性 */
  LINEAR = "linear",
  /** 面性 */
  SURFACE = "surface",
}

/** 图片上传组件形状 */
export type ImageCardShape = "circle" | "square";
