import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import { ValidationTypes } from "constants/WidgetValidation";
import { SetterConfig, Stylesheet } from "entities/AppTheming";
import React from "react";
import { DynamicHeight } from "utils/WidgetFeatures";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import BaseWidget, { WidgetProps, WidgetState } from "widgets/BaseWidget";

import AntdQrCodeComponent from "../component";
import { CodeStatus, Level } from "../constants";
import type { QRCodeProps } from "antd";

import IconSVG from "../icon.svg";

class AntdQrCodeWidget extends BaseWidget<AntdQrCodeWidgetProps, WidgetState> {
  static type = "ANTD_QRCODE_WIDGET";

  static getConfig() {
    return {
      name: "二维码", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
      isModule: false,
      tags: [WIDGET_TAGS.ANTD],
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 4,
        defaultValue: DynamicHeight.FIXED,
        active: true,
      },
    };
  }

  static getAutoLayoutConfig() {
    return {
      minWidth: "300px", 
      minHeight: "200px",
    };
  }

  static getDefaults() {
    return {
      widgetName: "Qrcode",
      bordered: false,
      value: "Qrcode",
      errorLevel: "M",
      bgColor: "transparent",
      color: "#000",
      status: "active",
      codeType: "svg",
      size: 160,
      rows: 20,
      columns: 20,
      minWidth: 300,
      minHeight: 200,
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "配置",
        children: [
          {
            propertyName: "value",
            label: "显示内容",
            helpText: "设置二维码扫描后的文本",
            controlType: "INPUT_TEXT",
            placeholderText: "输入文本内容或链接",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "icon",
            label: "配置Icon",
            helpText: "设置二维码扫描后的文本",
            controlType: "INPUT_TEXT",
            placeholderText: "输入链接或Base64",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          // {
          //   propertyName: "size",
          //   label: "配置二维码大小",
          //   helpText: "设置二维码大小",
          //   controlType: "INPUT_TEXT",
          //   placeholderText: "输入大小",
          //   isBindProperty: true,
          //   isTriggerProperty: false,
          //   validation: { type: ValidationTypes.NUMBER },
          // },
          {
            propertyName: "codeType",
            label: "二维码类型",
            controlType: "DROP_DOWN",
            helpText: "二维码类型",
            placeholderText: "选择二维码类型",
            options: [
              {
                label: "canvas",
                value: "canvas",
              },
              {
                label: "svg",
                value: "svg",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                allowedValues: ["canvas", "svg"],
                default: "svg",
              },
            },
          },
          {
            propertyName: "status",
            label: "二维码状态",
            controlType: "DROP_DOWN",
            helpText: "二维码状态",
            placeholderText: "选择二维码状态",
            options: [
              {
                label: "激活",
                value: CodeStatus.active,
              },
              {
                label: "已过期",
                value: CodeStatus.expired,
              },
              {
                label: "加载中",
                value: CodeStatus.loading,
              },
              {
                label: "已扫描",
                value: CodeStatus.scanned,
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                allowedValues: [
                  CodeStatus.active,
                  CodeStatus.expired,
                  CodeStatus.loading,
                  CodeStatus.scanned,
                ],
                default: CodeStatus.active,
              },
            },
          },
          {
            propertyName: "errorLevel",
            label: "容错等级",
            controlType: "DROP_DOWN",
            helpText: "容错等级",
            placeholderText: "选择容错等级",
            options: [
              {
                label: "低",
                value: Level.low,
              },
              {
                label: "中",
                value: Level.middle,
              },
              {
                label: "较高",
                value: Level.higher,
              },
              {
                label: "高",
                value: Level.high,
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                allowedValues: [
                  Level.low,
                  Level.middle,
                  Level.higher,
                  Level.high,
                ],
                default: Level.middle,
              },
            },
          },
        ],
      },
      {
        sectionName: "属性",
        children: [
          {
            helpText: "鼠标交互时显示的提示信息",
            propertyName: "tooltip",
            label: "提示",
            controlType: "INPUT_TEXT",
            placeholderText: "二维码提示",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "isVisible",
            label: "是否显示",
            helpText: "控制组件的显示/隐藏",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "animateLoading",
            label: "加载时显示动画",
            controlType: "SWITCH",
            helpText: "组件依赖的数据加载时显示加载动画",
            defaultValue: false,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "bordered",
            label: "是否显示边框",
            controlType: "SWITCH",
            helpText: "是否显示二维码边框",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "事件",
        children: [
          {
            helpText: "点击点击刷新的回调",
            propertyName: "onRefresh",
            label: "onRefresh",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "颜色配置",
        children: [
          {
            propertyName: "color",
            helpText: "修改二维码颜色",
            label: "二维码颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "bgColor",
            helpText: "修改二维码背景颜色",
            label: "二维码背景颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "轮廓样式",
        children: [
          {
            propertyName: "borderRadius",
            label: "边框圆角",
            helpText: "边框圆角样式",
            controlType: "BORDER_RADIUS_OPTIONS",
            isBindProperty: true,
            isJSConvertible: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "boxShadow",
            label: "阴影",
            helpText: "组件轮廓投影",
            controlType: "BOX_SHADOW_OPTIONS",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
        setValue: {
          path: "value",
          type: "string",
        },
        setIcon: {
          path: "icon",
          type: "string",
        },
        setStatus: {
          path: "status",
          type: "string",
        },
      },
    };
  }

  onRefresh = () => {
    if (this.props.onRefresh) {
      super.executeAction({
        triggerPropertyName: "onRefresh",
        dynamicString: this.props.onRefresh,
        event: {
          type: EventType.ON_QRCODE_REFRESH,
        },
      });
    }
  };

  getWidgetView() {
    const {
      value,
      icon,
      errorLevel,
      size,
      color,
      bgColor,
      borderRadius,
      boxShadow,
      bordered,
      status,
      tooltip,
      codeType
    } = this.props;
    return (
      <AntdQrCodeComponent
        value={value}
        icon={icon}
        codeType={codeType}
        errorLevel={errorLevel}
        size={size}
        color={color}
        bgColor={bgColor}
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        bordered={bordered}
        status={status}
        onRefresh={this.onRefresh}
        tooltip={tooltip}
      />
    );
  }
}

export interface AntdQrCodeWidgetProps extends WidgetProps {}

export default AntdQrCodeWidget;
