import { SegmenteSource } from "widgets/AntdSegmentedWidget/constants";
import type { AntdSegmentedWidgetProps } from "..";
import { Alignment } from "@blueprintjs/core";
import { generateReactKey } from "utils/generators";

export const updateSegmetdItemsSource = (
  props: AntdSegmentedWidgetProps,
  propertyPath: string,
  propertyValue: unknown,
): Array<{ propertyPath: string; propertyValue: unknown }> | undefined => {
  const propertiesToUpdate: Array<{
    propertyPath: string;
    propertyValue: unknown;
  }> = [];
  const isSegmetdItemsSourceChangedFromStaticToDynamic =
    props.segmetdItemsSource === SegmenteSource.STATIC &&
    propertyValue === SegmenteSource.DYNAMIC;
  if (isSegmetdItemsSourceChangedFromStaticToDynamic) {
    if (!props.options) {
      propertiesToUpdate.push({
        propertyPath: "options",
        propertyValue: [],
      });
    }

    if (!props.segmetdItems) {
      // const newSegmetdItemId = generateReactKey({ prefix: "segmetdItem" });
      // propertiesToUpdate.push({
      //   propertyPath: "segmetdItems",
      //   propertyValue: {
      //     [newSegmetdItemId]: {
      //       label: "是",
      //       id: newSegmetdItemId,
      //       value: "Y",
      //       iconName: "",
      //       iconAlign: Alignment.RIGHT,
      //     }
      //   },
      // });
    }
  }
  return propertiesToUpdate?.length ? propertiesToUpdate : undefined;
};
