import * as _ from "lodash";
import Top1 from "assets/images/screen/top1.png";
import Top3 from "assets/images/screen/top2.png";
import Top4 from "assets/images/screen/top4.png";
import Bottom4 from "assets/images/screen/bottom.png";
import BottomLeft from "assets/images/screen/bottomleft.png";
import BottomRight from "assets/images/screen/bottomright.png";
import StarGif from "assets/images/screen/star.gif";
import Top2 from "assets/images/screen/toptitle1.webp";

export const THEME_SETS = [
  {
    value: "set1",
    url: Top1,
  },
  {
    value: "set2",
    url: Top2,
  },
  {
    value: "set3",
    url: Top3,
  },
  {
    value: "set4",
    url: Top4,
  },
  {
    value: "set5",
    url: StarGif,
  },
];
const SET_THEMES = {};
THEME_SETS.map((it) => {
  const footerContainerStyle: any = {
    width: "100%",
    height: "10%",
    backgroundColor: "rgb(4, 4, 6)",
    position: "absolute",
    bottom: "0px",
    backgroundRepeat: "no-repeat",
  };
  let leftStyle: any;
  let rightStyle: any;
  if (it.value === "set1") {
    leftStyle = {
      background: `url(https://s3.bmp.ovh/imgs/2023/06/16/9d749c27e500718a.png)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      height: "100%",
      width: "30%",
      float: "left",
    };
    rightStyle = {
      background: `url(https://s3.bmp.ovh/imgs/2023/06/16/613656954ab7fd0c.png)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      height: "100%",
      width: "30%",
      float: "right",
    };
  } else if (it.value === "set4") {
    footerContainerStyle.backgroundImage = `url(https://s3.bmp.ovh/imgs/2023/06/19/107fde55dfa236c9.png)`;
    footerContainerStyle.backgroundPosition = "center";
    footerContainerStyle.backgroundSize = "60% 70%";
    footerContainerStyle.transform = `rotate(0deg) scaleY(-1)`;
  } else if (it.value === "set5") {
    footerContainerStyle.backgroundImage = `url(https://s3.bmp.ovh/imgs/2023/06/19/6580995b20ef2a6a.gif)`;
    footerContainerStyle.backgroundSize = "60% 25px";
    footerContainerStyle.backgroundPosition = "center";
  }
  _.set(
    SET_THEMES,
    [it.value, "footer", "containerStyle"],
    footerContainerStyle,
  );
  _.set(SET_THEMES, [it.value, "footer", "leftStyle"], leftStyle);
  _.set(SET_THEMES, [it.value, "footer", "rightStyle"], rightStyle);

  const headerContainerStyle: any = {
    width: "100%",
    height: "80px",
    // border: "1px solid green",
    backgroundColor: "rgb(4, 4, 6)",
    backgroundImage: `url('https://s3.bmp.ovh/imgs/2023/06/16/dac2354261be8768.webp')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
  };
  let leftHeader: any;
  let rightHeader: any;
  if (it.value === "set2") {
    headerContainerStyle.backgroundImage = `url(https://s3.bmp.ovh/imgs/2023/06/16/a472d72c338df51e.png)`;
  }
  if (it.value === "set3") {
    headerContainerStyle.backgroundImage = `url(https://s3.bmp.ovh/imgs/2023/06/19/4dda0c9456b5b613.png)`;
    headerContainerStyle.height = "30%";
    headerContainerStyle.position = "relative";
    leftHeader = {
      position: "absolute",
      left: "0",
      top: "12%",
      width: "33%",
      height: "auto",
      dataSrc: "https://s3.bmp.ovh/imgs/2023/06/19/1d0673bb1dd2e174.gif",
    };
    rightHeader = {
      position: "absolute",
      right: "0",
      width: "25%",
      top: "9%",
      height: "auto",
      dataSrc: "https://s3.bmp.ovh/imgs/2023/06/19/1d0673bb1dd2e174.gif",
    };
  }
  if (it.value === "set4") {
    headerContainerStyle.backgroundImage = `url(https://s3.bmp.ovh/imgs/2023/06/19/107fde55dfa236c9.png)`;
  }
  if (it.value === "set5") {
    headerContainerStyle.height = "14%";
    headerContainerStyle.backgroundImage = `url(https://s3.bmp.ovh/imgs/2023/06/19/6580995b20ef2a6a.gif)`;
    headerContainerStyle.backgroundSize = "40% auto";
    headerContainerStyle.backgroundPosition = "center";
  }
  _.set(
    SET_THEMES,
    [it.value, "header", "containerStyle"],
    headerContainerStyle,
  );
  _.set(SET_THEMES, [it.value, "header", "leftStyle"], leftHeader);
  _.set(SET_THEMES, [it.value, "header", "rightStyle"], rightHeader);
});

export const getHeaderFooterStyle = (theme?: string) => {
  const result: any = {};
  switch (theme) {
    case "set1":
    default:
      result.header = {
        left: {
          bgUrl: StarGif,
          className: "absolute bottom-0 left-1 w-2/5 h-1/4",
          bgStyle: "",
        },
        center: {
          bgUrl: Top1,
          className: "h-72 w-full",
          bgStyle: "",
        },
        right: {
          bgUrl: StarGif,
          bgStyle: "",
          className: "absolute bottom-1 right-4 w-1/4 h-1/3",
        },
      };
      break;
    case "set2":
      result.header = {
        left: {},
        center: {
          bgUrl: Top2,
          className: "h-20 w-full",
          bgStyle: ``,
        },
        right: {},
      };
      result.footer = {
        left: {
          bgUrl: BottomLeft,
          className: "absolute bottom-1 left-1 w-1/3 h-full ",
          bgStyle: "",
        },
        center: {},
        right: {
          bgUrl: BottomRight,
          bgStyle: "",
          className: "absolute bottom-1 right-1 w-1/3 h-full ",
        },
      };
      break;
    case "set3":
      result.header = {
        left: {},
        center: {
          bgUrl: Top3,
          className: "h-20 w-full",
          bgStyle: ``,
        },
        right: {},
      };
      result.footer = {
        left: {},
        center: {},
        right: {},
      };
      break;
    case "set4":
      result.header = {
        left: {},
        center: {
          bgUrl: Top4,
          className: "h-20 w-full",
          bgStyle: ``,
        },
        right: {},
      };
      result.footer = {
        left: {},
        center: {
          bgUrl: Bottom4,
          className: "h-10 w-full absolute bottom-1",
          bgStyle: ``,
        },
        right: {},
      };
      break;
    case "set5":
      result.header = {
        left: {},
        center: {
          bgUrl: StarGif,
          className: "h-4 w-full absolute bottom-2",
          bgStyle: ``,
        },
        right: {},
      };
      result.footer = {
        left: {},
        center: {
          bgUrl: StarGif,
          className: "h-4 w-full absolute top-4",
          bgStyle: ``,
        },
        right: {},
      };
      break;
  }
  return result;
};

export default { SET_THEMES, THEME_SETS };
