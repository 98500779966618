import React, { Suspense } from "react";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import { ValidationTypes } from "constants/WidgetValidation";
import LiquildBallComponent from "../component";
import type {
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";
import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import { Skeleton } from "antd";
import { retryPromise } from "utils/AppsmithUtils";

const LazyLiquildBallComponent = React.lazy(async () =>
  retryPromise(async () => import("../component")),
);
class LiquildBallWidget extends BaseWidget<
  LiquildBallWidgetProps,
  WidgetState
> {
  static type = "LIQUILDBALL_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return {
      name: "水球", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
      isScreen: true,
      tags: [WIDGET_TAGS.SCREEN],
    };
  }

  static getDefaults(): WidgetDefaultProps {
    return {
      widgetName: "LiquildBall",
      rows: 20,
      columns: 10,
      version: 1,
      lineWidth: 2,
      range: 0.4,
      data: 0.5,
      waveupsp: 0.006,
      sineColor: "#fbec99", // 水波颜色
      innerText: "#f6b71e", // 圈内文字
      outterCircle: "#fff89d", // 外圈
      bottomCircle: "#eaeaea", // 外底圈颜色
      percentCircle: "#fbdb32", // 进度圈颜色
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "300px",
            };
          },
        },
      ],
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "数据",
        children: [
          {
            helpText: "线宽",
            propertyName: "lineWidth",
            label: "线宽",
            controlType: "INPUT_TEXT",
            defaultValue: 2,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            helpText: "浪幅",
            propertyName: "range",
            label: "浪幅",
            controlType: "INPUT_TEXT",
            defaultValue: 0.4,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            helpText: "比例数值",
            propertyName: "data",
            label: "比例数值",
            controlType: "INPUT_TEXT",
            defaultValue: 0.5,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            helpText: "水波上涨速度",
            propertyName: "waveupsp",
            label: "水波上涨速度",
            controlType: "INPUT_TEXT",
            defaultValue: 0.006,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "样式",
        children: [
          {
            helpText: "水波颜色",
            propertyName: "sineColor",
            label: "水波颜色",
            controlType: "COLOR_PICKER",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            helpText: "圈内文字",
            propertyName: "innerText",
            label: "圈内文字",
            controlType: "COLOR_PICKER",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            helpText: "外圈颜色",
            propertyName: "outterCircle",
            label: "外圈",
            controlType: "COLOR_PICKER",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            helpText: "外圈底色",
            propertyName: "bottomCircle",
            label: "外圈底色",
            controlType: "COLOR_PICKER",
            isBindProperty: false,
            isTriggerProperty: false,
          },
          {
            helpText: "进度圈颜色",
            propertyName: "percentCircle",
            label: "进度圈",
            controlType: "COLOR_PICKER",
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
    ];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const { componentHeight, componentWidth } = this.props;
    const {
      bottomCircle,
      data,
      innerText,
      lineWidth,
      outterCircle,
      percentCircle,
      range,
      sineColor,
      waveupsp,
    } = this.props;
    const childProps = {
      lineWidth,
      range,
      data,
      waveupsp,
      sineColor,
      innerText,
      outterCircle,
      bottomCircle,
      percentCircle,
    };
    return (
      <Suspense fallback={<Skeleton />}>
        <LazyLiquildBallComponent
          height={componentHeight}
          width={componentWidth}
          {...childProps}
        />
      </Suspense>
    );
  }
}

export interface LiquildBallWidgetProps extends WidgetProps {
  height: any; // 高度
  width: any; // 宽度
  lineWidth: number; // 线宽
  range: number; // 浪幅
  data: number; // 比例数值
  waveupsp: number; // 水波上涨速度
  sineColor: string; // 水波颜色
  innerText: string; // 圈内文字
  outterCircle: string; // 外圈
  bottomCircle: string; // 外底圈颜色
  percentCircle: string; // 进度圈颜色
}

export default LiquildBallWidget;
