import type { ReactNode } from "react";

export interface SegmenteOption {
  label: string | ReactNode;
  value: string;
  disabled?: boolean;
}

export enum Direction {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export enum SegmenteSource {
  STATIC = "STATIC",
  DYNAMIC = "DYNAMIC",
}
