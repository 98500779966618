import React, { useState } from "react";
import BaseControl, { ControlData, ControlProps } from "./BaseControl";
import { Select } from "antd";
import { Icon, Collapse } from "@blueprintjs/core";
import { COLOR_SET } from "constants/ThemeConstants";
import ColorPickerComponent from "components/propertyControls/ColorPickerComponentV2";
import { isDynamicValue } from "utils/DynamicBindingUtils";
import {
  DSEventDetail,
  DSEventTypes,
  DS_EVENT,
  emitInteractionAnalyticsEvent,
} from "utils/AppsmithUtils";
// import tinycolor from "tinycolor2";
const DEFAULT_SET = "set1";

function ColorsSelect(props: any) {
  const { value, selectChange } = props;
  const [openme, setOpen] = useState<boolean>(false);
  const target: any = value
    ? COLOR_SET.find((c) => c.name === value)
    : COLOR_SET[0];
  const handleChange = (e: MouseEvent, key: string) => {
    e.stopPropagation();
    selectChange(key);
    setOpen(!openme);
  };
  return (
    <div
      className="w-full p-2 h-8 border border-slate-200 hover:border-slate-300 hover:bg-gray-50 cursor-pointer relative"
      onClick={() => setOpen(!openme)}
    >
      <div className="grid grid-cols-8 py-1 cursor-pointer">
        {target.colors.slice(0, 7).map((sc: string, idx: number) => (
          <div
            style={{
              background: sc,
              color: "transparent",
              height: "8px",
            }}
            key={`${sc}_${idx}`}
          >
            {"."}
          </div>
        ))}
        <div className="flex justify-end -my-1">
          <Icon icon="chevron-down" color="#a9a7a7" />
        </div>
      </div>

      <Select
        labelInValue
        open={openme}
        style={{
          width: "100%",
          height: "0px",
          visibility: "hidden",
          position: "absolute",
          left: 0,
          top: 5,
        }}
        //   onChange={props.handleChange}
        dropdownStyle={{
          padding: "1px 0px",
          borderRadius: "2px",
        }}
        dropdownRender={(menu) => (
          <div className="">
            {COLOR_SET.map((set) => (
              <div
                className="grid grid-cols-7 p-4 px-2 hover:bg-slate-100 cursor-pointer"
                key={set.name}
                onClick={(e: any) => handleChange(e, set.name)}
              >
                {set.colors.slice(0, 7).map((sc: string, idx: number) => (
                  <div
                    style={{
                      background: sc,
                      color: "transparent",
                      height: "8px",
                    }}
                    key={`${sc}_${idx}`}
                  >
                    {". "}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      />
    </div>
  );
}

class ColorPickerControl extends BaseControl<ColorPickerControlProps> {
  componentRef = React.createRef<HTMLDivElement>();

  // 改变配色方案
  handleChangeColor = (colorSet: string) => {
    this.updateProperty(this.props.propertyName, colorSet);
  };

  render() {
    const { evaluatedValue, defaultValue } = this.props;
    const value = evaluatedValue || defaultValue;
    return <ColorsSelect value={value} selectChange={this.handleChangeColor} />;
  }

  static getControlType() {
    return "COLOR_SET_PICKER";
  }

  static canDisplayValueInUI(config: ControlData, value: any): boolean {
    return !isDynamicValue(value);
  }
}

export interface ColorPickerControlProps extends ControlProps {
  defaultValue?: string;
}

export default ColorPickerControl;
