import { LabelPosition } from "components/constants";
import { BUTTON_MIN_WIDTH } from "constants/minWidthConstants";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import { ResponsiveBehavior } from "layoutSystems/common/utils/constants";
import { lazy } from "react";
import { retryPromise } from "utils/AppsmithUtils";
import { DynamicHeight } from "utils/WidgetFeatures";
import type { AnvilConfig } from "WidgetProvider/constants";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import BaseWidget, {
  type WidgetProps,
  type WidgetState,
} from "widgets/BaseWidget";
import { FileDataTypes } from "widgets/components/AntdUpload/transformFile";
import type { AntdUploadWidgetProps } from "../component";
import IconSVG from "../icon.svg";
import contentConfig from "./propertyConfig/contentConfig";
import styleConfig from "./propertyConfig/styleConfig";

const LazyButtonComponent = lazy(async () =>
  retryPromise(async () => import("../component")),
) as React.ComponentType<AntdUploadWidgetProps>;

class AntdUploadButtonWidget extends BaseWidget<
  AntdUploadButtonWidgetProps,
  WidgetState
> {
  static type = "ANTDUPLOADBUTTON_WIDGET";

  static getConfig() {
    return {
      name: "上传按钮",
      iconSVG: IconSVG,
      tags: [WIDGET_TAGS.ANTD],
      needsMeta: true,
      isModule: false,
      searchTags: ["upload", "file", "picker", "filepicker"],
    };
  }

  static getAnvilConfig(): AnvilConfig | null {
    return {
      isLargeWidget: false,
      widgetSize: {
        maxHeight: {},
        maxWidth: { base: "360px" },
        minHeight: { base: "40px" },
        minWidth: { base: "120px" },
      },
    };
  }

  static getAutoLayoutConfig() {
    return {
      disabledPropsDefaults: {
        labelPosition: LabelPosition.Top,
        labelTextSize: "0.875rem",
      },
      // defaults: {
      //   rows: 4,
      //   columns: 6.632,
      // },
      autoDimension: {
        width: true,
      },
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "120px",
              maxWidth: "360px",
              minHeight: "40px",
            };
          },
        },
      ],
      disableResizeHandles: {
        horizontal: true,
        vertical: true,
      },
    };
  }

  static getDefaults() {
    return {
      allowedFileTypes: [],
      label: "选择文件",
      buttonText: "上传文件",
      fileListVisible: true,
      resetOnSuccess: false,
      rows: 7,
      columns: 10,
      minFileSize: 0,
      fileDataType: FileDataTypes.NONE,
      dynamicTyping: true,
      widgetName: "AntdUploadButton",
      isDefaultClickDisabled: true,
      version: 1,
      isRequired: false,
      isDisabled: false,
      animateLoading: false,
      responsiveBehavior: ResponsiveBehavior.Hug,
      minWidth: BUTTON_MIN_WIDTH,
      buttonVariant: "PRIMARY",
      labelPosition: LabelPosition.Top,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 3,
        defaultValue: DynamicHeight.FIXED,
        active: true,
      },
      // [RegisteredWidgetFeatures.FLOAT_LAYOUT]: {
      //   sectionIndex: 3,
      //   active: true,
      // },
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      // ButtonVariantTypes.PRIMARY: "{{appsmith.theme.colors.primaryColor}}",
      buttonColor: "{{appsmith.theme.colors.primaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }

  static getPropertyPaneContentConfig() {
    return contentConfig;
  }

  static getPropertyPaneStyleConfig() {
    return styleConfig;
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
        setValue: {
          path: "value",
          type: "string",
        },
        setIcon: {
          path: "icon",
          type: "string",
        },
        setStatus: {
          path: "status",
          type: "string",
        },
      },
    };
  }

  getWidgetView() {
    const {
      allowedFileTypes,
      animateLoading,
      borderRadius,
      boxShadow,
      buttonColor,
      buttonText,
      buttonVariant,
      componentHeight,
      componentWidth,
      directory,
      fileDataType,
      fileList,
      fileListVisible,
      iconAlign,
      iconColor,
      iconName,
      isDisabled,
      isRequired,
      label,
      labelAlignment,
      labelColor,
      labelComponentWidth,
      labelPosition,
      labelTooltip,
      maxFileSize,
      maxNumFiles,
      minFileSize,
      onRemove,
      onUpload,
      placement,
      showColon,
      resetOnSuccess,
      textColor,
      tooltip,
      tooltipColor,
      updateWidgetMetaProperty,
      widgetId,
    } = this.props;

    return (
      <LazyButtonComponent
        allowedFileTypes={allowedFileTypes}
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        buttonColor={buttonColor}
        buttonText={buttonText}
        buttonVariant={buttonVariant}
        directory={directory}
        fileDataType={fileDataType}
        fileList={fileList}
        fileListVisible={fileListVisible}
        height={componentHeight}
        iconAlign={iconAlign}
        iconColor={iconColor}
        iconName={iconName}
        disabled={isDisabled}
        isRequired={isRequired}
        label={label}
        labelAlignment={labelAlignment}
        labelColor={labelColor}
        labelPosition={labelPosition}
        labelTooltip={labelTooltip}
        labelWidth={labelComponentWidth}
        maxFileSize={maxFileSize}
        maxNumFiles={maxNumFiles}
        minFileSize={minFileSize}
        onRemove={onRemove}
        placement={placement}
        showColon={showColon}
        textColor={textColor}
        tooltip={tooltip}
        tooltipColor={tooltipColor}
        updateWidgetMetaProperty={updateWidgetMetaProperty}
        widgetId={widgetId}
        width={componentWidth}
        animateLoading={animateLoading}
        onUpload={onUpload}
        resetOnSuccess={resetOnSuccess}
      />
    );
  }
}

export type AntdUploadButtonWidgetProps = WidgetProps & AntdUploadWidgetProps;

export default AntdUploadButtonWidget;
