import { Alignment } from "@blueprintjs/core";
import { ValidationTypes } from "constants/WidgetValidation";
import { ShapeType } from "widgets/components/AntdUpload/interface";
import type { AntdUploadImageWidgetProps } from "../index";

export default [
  {
    sectionName: "属性",
    children: [
      {
        propertyName: "shapeType",
        label: "形态变体",
        helpText: "设置组件的形态变体",
        controlType: "ICON_TABS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        options: [
          { label: "线性", value: ShapeType.LINEAR },
          { label: "面性", value: ShapeType.SURFACE },
        ],
        defaultValue: ShapeType.LINEAR,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
  {
    sectionName: "图标配置",
    children: [
      {
        propertyName: "iconName",
        label: "选择图标",
        helpText: "设置按钮图标",
        controlType: "ANTD_ICON_SELECT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        updateHook: (
          props: AntdUploadImageWidgetProps,
          propertyPath: string,
          propertyValue: any,
        ) => {
          const propertiesToUpdate = [{ propertyPath, propertyValue }];
          if (!props.iconAlign) {
            propertiesToUpdate.push({
              propertyPath: "iconAlign",
              propertyValue: Alignment.LEFT,
            });
          }
          return propertiesToUpdate;
        },
        dependencies: ["iconAlign"],
      },
    ],
  },
  {
    sectionName: "颜色配置",
    children: [
      {
        propertyName: "iconColor",
        helpText: "修改图标颜色",
        label: "图标颜色",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "textColor",
        helpText: "修改文本颜色",
        label: "组件文本颜色",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "labelColor",
        helpText: "修改标签颜色",
        label: "标签颜色",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "tooltipColor",
        helpText: "修改提示颜色",
        label: "提示文本颜色",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
  {
    sectionName: "轮廓样式",
    children: [
      {
        propertyName: "cardShape",
        helpText: "设置照片形状",
        label: "照片形状",
        controlType: "ICON_TABS",
        fullWidth: false,
        options: [
          { label: "方形", value: "square" },
          { label: "圆形", value: "circle" },
        ],
        defaultValue: "square",
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "borderRadius",
        label: "边框圆角",
        helpText: "边框圆角样式",
        controlType: "BORDER_RADIUS_OPTIONS",
        isBindProperty: true,
        isJSConvertible: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
        },
        hidden: (props: AntdUploadImageWidgetProps) =>
          props.cardShape === "circle",
        dependencies: ["cardShape"],
      },
      {
        propertyName: "boxShadow",
        label: "阴影",
        helpText: "组件轮廓投影",
        controlType: "BOX_SHADOW_OPTIONS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
];
