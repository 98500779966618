import { ValidationTypes } from "constants/WidgetValidation";
import type { AntdSegmentedWidgetProps } from "..";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";

const valueValidation = (
  propertyValue: unknown,
  props: AntdSegmentedWidgetProps,
) => {
  const invalidResponse = {
    isValid: false,
    parsed: propertyValue,
    messages: [
      {
        name: "DuplicateValue",
        message: "数值不能重复，必须是唯一",
      },
    ],
  };
  try {
    const { segmetdItems } = props;
    const isDuplicate = Object.values(segmetdItems).some(
      (item: any, index: number, array: any[]) =>
        array.findIndex((i) => i.value === item.value) !== index,
    );
    if (isDuplicate) {
      return invalidResponse;
    }
    return {
      isValid: true,
      parsed: propertyValue,
      message: "",
    };
  } catch (e) {
    return invalidResponse;
  }
};

export default {
  editableTitle: true,
  titlePropertyName: "label",
  panelIdPropertyName: "id",
  updateHook: (props: any, propertyPath: string, propertyValue: string) => {
    return [
      {
        propertyPath,
        propertyValue,
      },
    ];
  },
  contentChildren: [
    {
      sectionName: "属性",
      children: [
        {
          propertyName: "label",
          helpText: "设置选项的标签",
          label: "标签",
          controlType: "INPUT_TEXT",
          placeholderText: "是",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
        {
          propertyName: "value",
          helpText: "设置选项的值",
          label: "值",
          controlType: "INPUT_TEXT",
          placeholderText: "Y",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.FUNCTION,
            params: {
              fn: valueValidation,
              expected: {
                type: "string",
                example: "示例值",
                autocompleteDataType: AutocompleteDataType.STRING,
              },
            },
          },
        },
        {
          propertyName: "visiableItem",
          helpText: "控制选项是否显示",
          label: "是否显示选项",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.BOOLEAN,
          },
        },
        {
          propertyName: "disabledItem",
          helpText: "禁用选项",
          label: "禁用选项",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.BOOLEAN,
          },
        },
      ],
    },
  ],
  styleChildren: [
    {
      sectionName: "图标配置",
      children: [
        {
          propertyName: "iconName",
          label: "图标",
          helpText: "设置菜单项的图标",
          controlType: "ANTD_ICON_SELECT",
          isBindProperty: false,
          isTriggerProperty: false,
          // validation: { type: ValidationTypes.TEXT },
        },
        {
          propertyName: "iconAlign",
          label: "位置",
          helpText: "设置菜单项图标对齐方向",
          controlType: "ICON_TABS",
          defaultValue: "left",
          fullWidth: false,
          options: [
            {
              startIcon: "skip-left-line",
              value: "left",
            },
            {
              startIcon: "skip-right-line",
              value: "right",
            },
          ],
          isBindProperty: false,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
      ],
    },
    {
      sectionName: "颜色配置",
      children: [
        {
          propertyName: "iconColor",
          helpText: "设置选项图标颜色",
          label: "图标颜色",
          controlType: "COLOR_PICKER",
          isBindProperty: true,
          isTriggerProperty: false,
          isJSConvertible: true,
          customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
          evaluatedDependencies: ["sourceData"],
          validation: {
            type: ValidationTypes.ARRAY_OF_TYPE_OR_TYPE,
            params: {
              type: ValidationTypes.TEXT,
              regex: /^(?![<|{{]).+/,
            },
          },
        },
      ],
    },
  ],
};
