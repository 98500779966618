import React, { Suspense } from "react";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import { EvaluationSubstitutionType } from "entities/DataTree/dataTreeFactory";

import { ValidationTypes } from "constants/WidgetValidation";

import HydrographComponent from "../component";
import type {
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";
import IconSVG from "../icon.svg";
import { Skeleton } from "antd";
import { retryPromise } from "utils/AppsmithUtils";

const LazyHydrographComponent = React.lazy(async () =>
  retryPromise(async () => import("../component")),
);

class HydrographWidget extends BaseWidget<HydrographWidgetProps, WidgetState> {
  static type = "HYDROGRAPH_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return {
      name: "水位图", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isScreen: true,
      tags: [WIDGET_TAGS.SCREEN],
    };
  }

  static getDefaults(): WidgetDefaultProps {
    return {
      widgetName: "Hydrograph",
      rows: 12,
      columns: 12,
      version: 1,
      data: [55],
      shape: "rect",
      colors: ["#00BAFF", "#3DE7C9"],
      waveNum: 3,
      waveHeight: 40,
      waveOpacity: 0.4,
      formatter: "{value}%",
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "300px",
            };
          },
        },
      ],
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "数据",
        children: [
          {
            helpText: "水位位置",
            propertyName: "data",
            label: "水位数据",
            controlType: "INPUT_TEXT",
            placeholderText: "[55]",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.NUMBER,
                },
              },
            },
            evaluationSubstitutionType:
              EvaluationSubstitutionType.SMART_SUBSTITUTE,
          },
        ],
      },
      {
        sectionName: "属性",
        children: [
          {
            helpText: "水位图配色",
            propertyName: "colors",
            label: "配色",
            controlType: "INPUT_TEXT",
            placeholderText: `["#00BAFF", "#3DE7C9"]`,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.TEXT,
                },
              },
            },
            evaluationSubstitutionType:
              EvaluationSubstitutionType.SMART_SUBSTITUTE,
          },
          {
            helpText: "格式化",
            propertyName: "formatter",
            label: "信息格式化	",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入信息格式",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "波浪数量",
            propertyName: "waveNum",
            label: "波浪数量",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入波浪数量",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            helpText: "波浪高度",
            propertyName: "waveHeight",
            label: "高度",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入波浪高度",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            helpText: "波浪透明度	",
            propertyName: "waveOpacity",
            label: "透明度	",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入波浪透明度",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            helpText: "水位图形状",
            propertyName: "shape",
            label: "形状",
            controlType: "ICON_TABS",
            fullWidth: true,
            options: [
              {
                label: "矩形",
                value: "rect",
              },
              {
                label: "圆角矩形",
                value: "roundRect",
              },
              {
                label: "圆形",
                value: "round",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const { colors, data, formatter, shape, waveHeight, waveNum, waveOpacity } =
      this.props;
    return (
      <Suspense fallback={<Skeleton />}>
        <LazyHydrographComponent
          colors={colors}
          data={data}
          formatter={formatter}
          shape={shape}
          waveHeight={waveHeight}
          waveNum={waveNum}
          waveOpacity={waveOpacity}
        />
      </Suspense>
    );
  }
}

export interface HydrographWidgetProps extends WidgetProps {
  data: any;
  shape: string;
  colors?: string;
  waveNum: number;
  waveHeight: number;
  waveOpacity: number;
  formatter: string;
}

export default HydrographWidget;
