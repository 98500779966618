import { SectionWidget } from "./anvil/SectionWidget";
import { ZoneWidget } from "./anvil/ZoneWidget";
import AudioRecorderWidget from "./AudioRecorderWidget";
import AudioWidget from "./AudioWidget";
import type BaseWidget from "./BaseWidget";
import ButtonGroupWidget from "./ButtonGroupWidget";
import ButtonWidget from "./ButtonWidget";
import CameraWidget from "./CameraWidget";
import CanvasWidget from "./CanvasWidget";
import CategorySliderWidget from "./CategorySliderWidget";
import ChartWidget from "./ChartWidget";
import CheckboxGroupWidget from "./CheckboxGroupWidget";
import CheckboxWidget from "./CheckboxWidget";
import CircularProgressWidget from "./CircularProgressWidget";
import CodeScannerWidget from "./CodeScannerWidget";
import QrCodeScannerWidget from "./CodeScannerWidgetV2";
import ContainerWidget from "./ContainerWidget";
import CurrencyInputWidget from "./CurrencyInputWidget";
import CustomWidget from "./CustomWidget";
import DatePickerWidget from "./DatePickerWidget";
import DatePickerWidget2 from "./DatePickerWidget2";
import DividerWidget from "./DividerWidget";
import DocumentViewerWidget from "./DocumentViewerWidget";
import DropdownWidget from "./DropdownWidget";
import ExternalWidget from "./ExternalWidget";
import FilePickerWidget from "./FilepickerWidget";
import FilePickerWidgetV2 from "./FilePickerWidgetV2";
import FormButtonWidget from "./FormButtonWidget";
import FormWidget from "./FormWidget";
import IconButtonWidget from "./IconButtonWidget";
import IconWidget from "./IconWidget";
import IframeWidget from "./IframeWidget";
import ImageWidget from "./ImageWidget";
import InputWidget from "./InputWidget";
import InputWidgetV2 from "./InputWidgetV2";
import JSONFormWidget from "./JSONFormWidget";
import ListWidget from "./ListWidget";
import ListWidgetV2 from "./ListWidgetV2";
import MapChartWidget from "./MapChartWidget";
import MapWidget from "./MapWidget";
import MenuButtonWidget from "./MenuButtonWidget";
import ModalWidget from "./ModalWidget";
import MultiSelectTreeWidget from "./MultiSelectTreeWidget";
import MultiSelectWidget from "./MultiSelectWidget";
import MultiSelectWidgetV2 from "./MultiSelectWidgetV2";
import NumberSliderWidget from "./NumberSliderWidget";
import PhoneInputWidget from "./PhoneInputWidget";
import ProgressBarWidget from "./ProgressBarWidget";
import ProgressWidget from "./ProgressWidget";
import RadioGroupWidget from "./RadioGroupWidget";
import RangeSliderWidget from "./RangeSliderWidget";
import RateWidget from "./RateWidget";
import RichTextEditorWidget from "./RichTextEditorWidget";
import SelectWidget from "./SelectWidget";
import SingleSelectTreeWidget from "./SingleSelectTreeWidget";
import SkeletonWidget from "./SkeletonWidget";
import StatboxWidget from "./StatboxWidget";
import SwitchGroupWidget from "./SwitchGroupWidget";
import SwitchWidget from "./SwitchWidget";
import TableWidget from "./TableWidget";
import TableWidgetV2 from "./TableWidgetV2";
import TabsMigratorWidget from "./TabsMigrator";
import TabsWidget from "./TabsWidget";
import TextWidget from "./TextWidget";
import VideoWidget from "./VideoWidget";
import { WDSButtonWidget } from "./wds/WDSButtonWidget";
import { WDSCheckboxGroupWidget } from "./wds/WDSCheckboxGroupWidget";
import { WDSCheckboxWidget } from "./wds/WDSCheckboxWidget";
import { WDSCurrencyInputWidget } from "./wds/WDSCurrencyInputWidget";
import { WDSHeadingWidget } from "./wds/WDSHeadingWidget";
import { WDSIconButtonWidget } from "./wds/WDSIconButtonWidget";
import { WDSInlineButtonsWidget } from "./wds/WDSInlineButtonsWidget";
import { WDSInputWidget } from "./wds/WDSInputWidget";
import { WDSKeyValueWidget } from "./wds/WDSKeyValueWidget";
import { WDSMenuButtonWidget } from "./wds/WDSMenuButtonWidget";
import { WDSModalWidget } from "./wds/WDSModalWidget";
import { WDSParagraphWidget } from "./wds/WDSParagraphWidget";
import { WDSPhoneInputWidget } from "./wds/WDSPhoneInputWidget";
import { WDSRadioGroupWidget } from "./wds/WDSRadioGroupWidget";
import { WDSStatBoxWidget } from "./wds/WDSStatBoxWidget";
import { WDSSwitchGroupWidget } from "./wds/WDSSwitchGroupWidget";
import { WDSSwitchWidget } from "./wds/WDSSwitchWidget";
import { WDSTableWidget } from "./wds/WDSTableWidget";
import { WDSToolbarButtonsWidget } from "./wds/WDSToolbarButtonsWidget";

import EchartWidget from "./EchartWidget";
import FormilyWidget from "./FormilyWidget";

import AntdButtonGroupWidget from "./AntdButtonGroupWidget";
import AntdButtonWidget from "./AntdButtonWidget";
import AntdIconButtonWidget from "./AntdIconButtonWidget";
import AntdMenuButtonWidget from "./AntdMenuButtonWidget";
import AntdMultiSelectTreeWidget from "./AntdMultiSelectTreeWidget";
import AntdMultiSelectWidget from "./AntdMultiSelectWidgetV2/widget";
import AntdQrCodeWidget from "./AntdQrCodeWidget/widget";
import AntdSelectWidget from "./AntdSelectWidget";
import AntdSingleSelectTreeWidget from "./AntdSingleSelectTreeWidget";
import AntdSwitchGroupWidget from "./AntdSwitchGroupWidget";
import AntdSwitchWidget from "./AntdSwitchWidget";
import AntdUploadButtonWidget from "./AntdUploadButtonWidget/widget";
import AntdUploadDraggerWidget from "./AntdUploadDraggerWidget/widget";
import AntdUploadImageWidget from "./AntdUploadImageWidget/widget";
import AvatarWidget from "./AvatarWidget";
import CapsuleChartBarWidget from "./CapsuleChartBarWidget";
import ScreenContainerComWidget from "./ContainerScreenCompWidget/widget";
import ScreenContainerWidget from "./ContainerScreenWidget/widget";
import CustomComponentWidget from "./CustomComponentWidget";
import DigitalFlopWidget from "./DigitalFlopWidget";
import HydrographWidget from "./HydrographWidget";
import JsonSchemaFormWidget from "./JsonSchemaFormWidget";
import LiquildBallWidget from "./LiquildBallWidget";
import PercentPondWidget from "./PercentPondWidget";
import RealtimeClockWidget from "./RealtimeClockWidget";
import ScrollBoardWidget from "./ScrollBoardWidget";
import ScrollRankingBoardWidget from "./ScrollRankingBoardWidget";
import TableWidgetV3 from "./TableWidgetV3";
import MActionBarWidget from "./taro/ActionBarWidget";
import MBottomBarWidget from "./taro/BottomBarWidget";
import MButtonWidget from "./taro/ButtonWidget";
import MCellWidget from "./taro/CellWidget";
import MCheckboxWidget from "./taro/CheckboxWidget";
import MFormWidget from "./taro/FormWidget";
import GridWidget from "./taro/GridWidget";
import MHtmlWidget from "./taro/HtmlWidget";
import MImageWidget from "./taro/ImageWidget";
import MKVWidget from "./taro/KVWidget";
import MListWidget from "./taro/ListWidget";
import MLoadingWidget from "./taro/LoadingWidget";
import MPopupWidget from "./taro/PopupWidget";
import MSearchWidget from "./taro/SearchWidget";
import MSkuWidget from "./taro/SkuWidget";
import SwiperWidget from "./taro/SwiperWidget";
import MTabsWidget from "./taro/TabsWidget";
import MTextWidget from "./taro/TextWidget";
import TextScreenWidget from "./TextScreenWidget";
import TreeWidget from "./TreeWidget";
import AntdSegmentedWidget from "./AntdSegmentedWidget";
// import AntdTableWidgetV2 from "./AntdTableWidgetV2/widget";

const AntdWidgets = [
  AntdButtonWidget,
  AntdIconButtonWidget,
  AntdButtonGroupWidget,
  AntdMenuButtonWidget,
  AntdSwitchWidget,
  AntdSwitchGroupWidget,
  AntdSelectWidget,
  AntdMultiSelectWidget,
  AntdSingleSelectTreeWidget,
  AntdMultiSelectTreeWidget,
  AntdQrCodeWidget,
  // AntdTableWidgetV2,
  AntdUploadButtonWidget,
  AntdUploadDraggerWidget,
  AntdUploadImageWidget,
  AntdSegmentedWidget,
];

const LegacyWidgets = [
  // appsmith
  CanvasWidget,
  SkeletonWidget,
  ContainerWidget,
  // ProContainerWidget,
  TextWidget,
  TableWidget,
  CheckboxWidget,
  RadioGroupWidget,
  ButtonWidget,
  ImageWidget,
  VideoWidget,
  TabsWidget,
  ModalWidget,
  ChartWidget,
  MapWidget,
  RichTextEditorWidget,
  DatePickerWidget2,
  SwitchWidget,
  FormWidget,
  RateWidget,
  IframeWidget,
  TabsMigratorWidget,
  DividerWidget,
  MenuButtonWidget,
  IconButtonWidget,
  CheckboxGroupWidget,
  FilePickerWidgetV2,
  StatboxWidget,
  AudioRecorderWidget,
  DocumentViewerWidget,
  ButtonGroupWidget,
  MultiSelectTreeWidget,
  SingleSelectTreeWidget,
  SwitchGroupWidget,
  AudioWidget,
  ProgressBarWidget,
  CameraWidget,
  MapChartWidget,
  SelectWidget,
  MultiSelectWidgetV2,
  InputWidgetV2,
  PhoneInputWidget,
  CurrencyInputWidget,
  JSONFormWidget,
  TableWidgetV2,
  NumberSliderWidget,
  RangeSliderWidget,
  CategorySliderWidget,
  CodeScannerWidget,
  QrCodeScannerWidget,
  ListWidgetV2,
  ExternalWidget,
  TableWidgetV3,

  //pageplug
  CustomComponentWidget,
  JsonSchemaFormWidget,
  TreeWidget,
  FormilyWidget,
  EchartWidget,
  AvatarWidget,
  CapsuleChartBarWidget,
  ScreenContainerComWidget,
  ScreenContainerWidget,
  DigitalFlopWidget,
  HydrographWidget,
  LiquildBallWidget,
  PercentPondWidget,
  RealtimeClockWidget,
  ScrollBoardWidget,
  ScrollRankingBoardWidget,
  TextScreenWidget,
  CustomWidget,

  //taro
  MActionBarWidget,
  MBottomBarWidget,
  MButtonWidget,
  MCellWidget,
  MCheckboxWidget,
  MFormWidget,
  GridWidget,
  MHtmlWidget,
  MImageWidget,
  MKVWidget,
  MListWidget,
  MLoadingWidget.WrappedComponent,
  MPopupWidget.WrappedComponent,
  MSearchWidget,
  MSkuWidget,
  SwiperWidget,
  MTabsWidget,
  MTextWidget,
];

const DeprecatedWidgets = [
  //Deprecated Widgets
  InputWidget,
  DropdownWidget,
  DatePickerWidget,
  IconWidget,
  FilePickerWidget,
  MultiSelectWidget,
  FormButtonWidget,
  ProgressWidget,
  CircularProgressWidget,
  ListWidget,
];

const WDSWidgets = [
  // WDS Widgets
  WDSButtonWidget,
  WDSInputWidget,
  WDSCheckboxWidget,
  WDSIconButtonWidget,
  WDSTableWidget,
  WDSCurrencyInputWidget,
  WDSToolbarButtonsWidget,
  WDSPhoneInputWidget,
  WDSCheckboxGroupWidget,
  WDSSwitchWidget,
  WDSSwitchGroupWidget,
  WDSRadioGroupWidget,
  WDSMenuButtonWidget,
  CustomWidget,
  SectionWidget,
  ZoneWidget,
  WDSParagraphWidget,
  WDSHeadingWidget,
  WDSModalWidget,
  WDSStatBoxWidget,
  WDSKeyValueWidget,
  WDSInlineButtonsWidget,
];

const Widgets = [
  ...WDSWidgets,
  ...DeprecatedWidgets,
  ...LegacyWidgets,
  ...AntdWidgets,
] as (typeof BaseWidget)[];

export default Widgets;
