import type { DerivedPropertiesMap } from "WidgetProvider/factory";

import { LabelPosition } from "components/constants";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import type { Stylesheet } from "entities/AppTheming";
import { ResponsiveBehavior } from "layoutSystems/common/utils/constants";
import { lazy } from "react";
import { retryPromise } from "utils/AppsmithUtils";
import { DynamicHeight } from "utils/WidgetFeatures";
import BaseWidget, {
  type WidgetProps,
  type WidgetState,
} from "widgets/BaseWidget";
import { ShapeType } from "widgets/components/AntdUpload/interface";
import { FileDataTypes } from "widgets/components/AntdUpload/transformFile";
import type { AntdUploadWidgetProps } from "../component";
import IconSVG from "../icon.svg";
import contentConfig from "./propertyConfig/contentConfig";
import styleConfig from "./propertyConfig/styleConfig";

const LazyComponent = lazy(async () =>
  retryPromise(async () => import("../component")),
) as React.ComponentType<AntdUploadWidgetProps>;

class AntdUploadImageWidget extends BaseWidget<
  AntdUploadImageWidgetProps,
  WidgetState
> {
  static type = "ANTDUPLOADIMAGE_WIDGET";

  static getConfig() {
    return {
      name: "图片上传",
      iconSVG: IconSVG,
      tags: [WIDGET_TAGS.ANTD],
      needsMeta: true,
      isModule: false,
      searchTags: ["upload", "file", "picker", "image"],
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 3,
        defaultValue: DynamicHeight.FIXED,
        active: true,
      },
    };
  }

  static getDefaults() {
    return {
      allowedFileTypes: [],
      label: "选择文件",
      text: "上传图片",
      tooltip: "只支持图片格式",
      rows: 18,
      columns: 40,
      iconName: JSON.stringify({
        iconName: "icon-plus",
        type: "antd",
        category: "tips",
      }),
      minFileSize: 0,
      fileDataType: FileDataTypes.NONE,
      resetOnSuccess: false,
      dynamicTyping: true,
      widgetName: "AntdUploadImage",
      isDefaultClickDisabled: true,
      version: 1,
      isRequired: false,
      isDisabled: false,
      animateLoading: false,
      responsiveBehavior: ResponsiveBehavior.Hug,
      labelPosition: LabelPosition.Top,
      shapeType: ShapeType.LINEAR,
    };
  }

  static getPropertyPaneContentConfig() {
    return contentConfig;
  }

  static getPropertyPaneStyleConfig() {
    return styleConfig;
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      // ButtonVariantTypes.PRIMARY: "{{appsmith.theme.colors.primaryColor}}",
      iconColor: "{{appsmith.theme.colors.primaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }

  getWidgetView() {
    const {
      animateLoading,
      borderRadius,
      boxShadow,
      cardShape,
      fileDataType,
      fileList,
      iconColor,
      iconName,
      isDisabled,
      isRequired,
      label,
      labelAlignment,
      labelColor,
      labelComponentWidth,
      labelPosition,
      labelTooltip,
      maxFileSize,
      maxNumFiles,
      minFileSize,
      onRemove,
      onUpload,
      shapeType,
      showColon,
      text,
      resetOnSuccess,
      textColor,
      tooltip,
      tooltipColor,
      updateWidgetMetaProperty,
      widgetId,
    } = this.props;

    return (
      <LazyComponent
        animateLoading={animateLoading}
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        shape={cardShape}
        shapeType={shapeType}
        fileDataType={fileDataType}
        fileList={fileList}
        iconColor={iconColor}
        iconName={iconName}
        isDisabled={isDisabled}
        isRequired={isRequired}
        label={label}
        labelAlignment={labelAlignment}
        labelColor={labelColor}
        labelPosition={labelPosition}
        labelTooltip={labelTooltip}
        labelWidth={labelComponentWidth}
        maxFileSize={maxFileSize}
        maxNumFiles={maxNumFiles}
        minFileSize={minFileSize}
        onRemove={onRemove}
        onUpload={onUpload}
        showColon={showColon}
        text={text}
        textColor={textColor}
        tooltip={tooltip}
        tooltipColor={tooltipColor}
        updateWidgetMetaProperty={updateWidgetMetaProperty}
        widgetId={widgetId}
        resetOnSuccess={resetOnSuccess}
      />
    );
  }
}

export type AntdUploadImageWidgetProps = WidgetProps & AntdUploadWidgetProps;

export default AntdUploadImageWidget;
