import React, { Suspense } from "react";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import { EvaluationSubstitutionType } from "entities/DataTree/dataTreeFactory";

import { ValidationTypes } from "constants/WidgetValidation";
import ScrollRankingBoardComponent from "../component";
import IconSVG from "../icon.svg";
import type {
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";
import { Skeleton } from "antd";
import { retryPromise } from "utils/AppsmithUtils";


const LazyScrollRankingBoardComponent = React.lazy(async () =>
  retryPromise(async () => import("../component")),
);
class ScrollRankingBoardWidget extends BaseWidget<
  ScrollRankingBoardWidgetProps,
  WidgetState
> {
  static type = "SCROLLRANKINGBOARD_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return {
      name: "排名轮播", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isScreen: true,
      tags: [WIDGET_TAGS.SCREEN],
      searchTags: ["rank", "No", "排名"],
    };
  }

  static getDefaults(): WidgetDefaultProps {
    return {
      widgetName: "ScrollRankingBoard",
      rows: 25,
      columns: 15,
      version: 1,
      data: [
        {
          name: "周口",
          value: 55,
        },
        {
          name: "南阳",
          value: 120,
        },
        {
          name: "西峡",
          value: 78,
        },
        {
          name: "驻马店",
          value: 66,
        },
        {
          name: "新乡",
          value: 80,
        },
        {
          name: "信阳",
          value: 45,
        },
        {
          name: "漯河",
          value: 29,
        },
      ],
      unit: "单位",
      carousel: "single",
      waitTime: 2000,
      rowNum: 5,
      sort: true,
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "300px",
            };
          },
        },
      ],
    };
  }

  static getPropertyPaneContentConfig(): any[] {
    return [
      {
        sectionName: "数据",
        children: [
          {
            helpText: "表数据",
            propertyName: "data",
            label: "表数据",
            controlType: "INPUT_TEXT",
            placeholderText: '[{ "value": 123, "name": "value1" }]',
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    required: true,
                    allowedKeys: [
                      {
                        name: "name",
                        type: ValidationTypes.TEXT,
                        params: {
                          default: "",
                          requiredKey: true,
                        },
                      },
                      {
                        name: "value",
                        type: ValidationTypes.NUMBER,
                        params: {
                          default: "",
                          requiredKey: true,
                        },
                      },
                    ],
                  },
                },
              },
            },
            evaluationSubstitutionType:
              EvaluationSubstitutionType.SMART_SUBSTITUTE,
          },
        ],
      },
      {
        sectionName: "属性",
        children: [
          {
            helpText: "表行数",
            propertyName: "rowNum",
            label: "表行数",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入表行数",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            helpText: "数值单位	",
            propertyName: "unit",
            label: "数值单位",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入数值单位",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            helpText: "轮播时间间隔(ms)",
            propertyName: "waitTime",
            label: "轮播时间间隔(ms)",
            controlType: "INPUT_TEXT",
            placeholderText: "请输入轮播时间间隔(ms)",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.NUMBER },
          },
          {
            helpText: "轮播方式",
            propertyName: "carousel",
            label: "轮播方式",
            placeholderText: "轮播方式",
            controlType: "ICON_TABS",
            fullWidth: true,
            options: [
              {
                label: "单条",
                value: "single",
              },
              {
                label: "整页",
                value: "page",
              },
            ],
          },
          {
            helpText: "自动排序",
            propertyName: "sort",
            label: "自动排序",
            controlType: "SWITCH",
            placeholderText: "请输入自动排序",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  getWidgetView() {
    const { carousel, data, rowNum, sort, unit, waitTime } = this.props;
    const childProps = {
      data,
      rowNum,
      waitTime,
      carousel,
      unit,
      sort,
    };
    return (
      <Suspense fallback={<Skeleton />}>
        <LazyScrollRankingBoardComponent {...childProps} />
      </Suspense>
    );
  }
}

export interface ScrollRankingBoardWidgetProps extends WidgetProps {
  data: any;
  rowNum: number;
  waitTime: number;
  carousel: string;
  unit: string;
  sort: boolean;
}

export default ScrollRankingBoardWidget;
