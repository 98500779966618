import { Alignment } from "@blueprintjs/core";
import { ButtonPlacementTypes } from "components/constants";
import { ValidationTypes } from "constants/WidgetValidation";
import { ShapeType } from "widgets/components/AntdUpload/interface";
import type { AntdUploadDraggerWidgetProps } from "../index";

export default [
  {
    sectionName: "属性",
    children: [
      {
        propertyName: "shapeType",
        label: "形态变体",
        helpText: "设置组件的形态变体",
        controlType: "ICON_TABS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        options: [
          { label: "线性", value: ShapeType.LINEAR },
          { label: "面性", value: ShapeType.SURFACE },
        ],
        defaultValue: ShapeType.LINEAR,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
  {
    sectionName: "图标配置",
    children: [
      {
        propertyName: "iconName",
        label: "选择图标",
        helpText: "设置按钮图标",
        controlType: "ANTD_ICON_SELECT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        updateHook: (
          props: AntdUploadDraggerWidgetProps,
          propertyPath: string,
          propertyValue: any,
        ) => {
          const propertiesToUpdate = [{ propertyPath, propertyValue }];
          if (!props.iconAlign) {
            propertiesToUpdate.push({
              propertyPath: "iconAlign",
              propertyValue: Alignment.LEFT,
            });
          }
          return propertiesToUpdate;
        },
        dependencies: ["iconAlign"],
      },
      {
        propertyName: "iconAlign",
        label: "位置",
        helpText: "设置按钮图标对齐方向",
        controlType: "ICON_TABS",
        defaultValue: "left",
        fullWidth: false,
        options: [
          {
            startIcon: "skip-left-line",
            value: "left",
          },
          {
            startIcon: "skip-right-line",
            value: "right",
          },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: ["center", "left", "right"],
          },
        },
      },
      {
        propertyName: "placement",
        label: "排列方式",
        controlType: "ICON_TABS",
        fullWidth: true,
        helpText: "设置图标与标签的排列方式",
        options: [
          {
            label: "向前对齐",
            value: ButtonPlacementTypes.START,
          },
          {
            label: "两边对齐",
            value: ButtonPlacementTypes.BETWEEN,
          },
          {
            label: "居中对齐",
            value: ButtonPlacementTypes.CENTER,
          },
        ],
        defaultValue: ButtonPlacementTypes.CENTER,
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: [
              ButtonPlacementTypes.START,
              ButtonPlacementTypes.BETWEEN,
              ButtonPlacementTypes.CENTER,
            ],
            default: ButtonPlacementTypes.CENTER,
          },
        },
      },
    ],
  },
  {
    sectionName: "颜色配置",
    children: [
      {
        propertyName: "iconColor",
        helpText: "修改图标颜色",
        label: "图标颜色",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "textColor",
        helpText: "修改文本颜色",
        label: "组件文本颜色",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "labelColor",
        helpText: "修改标签颜色",
        label: "标签颜色",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "tooltipColor",
        helpText: "修改提示颜色",
        label: "提示文本颜色",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
  {
    sectionName: "轮廓样式",
    children: [
      {
        propertyName: "borderRadius",
        label: "边框圆角",
        helpText: "边框圆角样式",
        controlType: "BORDER_RADIUS_OPTIONS",
        isBindProperty: true,
        isJSConvertible: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
        },
      },
      {
        propertyName: "boxShadow",
        label: "阴影",
        helpText: "组件轮廓投影",
        controlType: "BOX_SHADOW_OPTIONS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
];
